import React, { useState, useEffect } from 'react';
import Dropdown from './DropdownOptions'; 
import TournamentConfirmation from './ConfirmTourneyCreation'; // Import the modal component

const getTimeNow = (setHour, setMinute, setDate) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const currentHour = today.getHours();
  const currentMinute = today.getMinutes();

  const formattedDate = month.toString().padStart(2, '0') + '/' + day.toString().padStart(2, '0');
  const formattedMinute = currentMinute.toString().padStart(2, '0');
  const nextHour = (currentHour + 1).toString().padStart(2, '0');

  setHour(nextHour);
  setMinute(formattedMinute);
  setDate(formattedDate);
};

const TournamentForm = () => {
  const [variation, setVariation] = useState('Standard');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [date, setDate] = useState('');
  const [size, setSize] = useState('');
  const [mode, setMode] = useState('');
  const [wager, setWager] = useState('');
  const [minElo, setMinElo] = useState('-∞');
  const [maxElo, setMaxElo] = useState('+∞');
  const [tourneyName, setTourneyName] = useState('');
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [freeCashPrize, setFreeCashPrize] = useState(false); // State to toggle wager input

  const variationOptions = ['Standard', 'King of the Hill'];
  const hourOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minuteOptions = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  const sizeOptions = ['4-Man', '8-Man', 'No Limit'];
  const modeOptions = ['1 Min', '2 | 1', '3 Min', '3 | 2', '5 Min', '10 Min', '30 Min'];
  const wagerOptions = ['0', '10,000', '50,000', '100,000', '200,000'];
  const minEloOptions = ['-∞', '-400', '-200', '-150', '-100', '-50', '-25'];
  const maxEloOptions = ['+∞', '+400', '+200', '+150', '+100', '+50', '+25'];

  useEffect(() => {
    getTimeNow(setHour, setMinute, setDate);
  }, []);

  const isFormValid = () => {
    // Basic validation: Check if all required fields are filled
    if (!tourneyName || !hour || !minute || !size || !mode) {
      return false;
    }
  
    // Additional validation for free cash prize
    if (freeCashPrize) {
      const cashPrizeAmount = parseFloat(wager);
  
      // Check that the wager is a valid number, greater than 0, and no more than $500
      if (!wager || isNaN(cashPrizeAmount) || cashPrizeAmount <= 0 || cashPrizeAmount > 500) {
        return false;
      }
    }
  
    // Validate time: ensure selected time is at least 5 minutes from now
    const currentTime = new Date();
    const selectedTime = new Date();
    selectedTime.setHours(Number(hour));
    selectedTime.setMinutes(Number(minute));
  
    const diff = (selectedTime - currentTime) / 60000; // Difference in minutes
  
    if (diff < 5) {
      return false;
    }
  
    return true;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (isFormValid()) {
      setShowConfirmation(true);
      setError(''); // Clear error on successful submission
    } else {
      // Set an appropriate error message for free cash prize validation
      if (freeCashPrize) {
        const cashPrizeAmount = parseFloat(wager);
        if (!wager) {
          setError('Please enter a cash prize.');
        } else if (isNaN(cashPrizeAmount) || cashPrizeAmount <= 0) {
          setError('Cash prize must be greater than $0.');
        } else if (cashPrizeAmount > 500) {
          setError('Cash prize cannot exceed $500.');
        }
      } else {
        setError('All fields are required.');
      }
    }
  };

  const handleInputChange = () => {
    if (isFormValid()) {
      setError('');
    } else {
      if (hour && minute && size && mode && wager && tourneyName.length > 0) {
        if(freeCashPrize){
          const cashPrizeAmount = parseFloat(wager);
          console.log('error wager', wager);
          if (!wager) {
            setError('Please enter a cash prize.');
          } else if (isNaN(cashPrizeAmount) || cashPrizeAmount <= 0) {
            setError('Cash prize must be greater than $0.');
          } else if (cashPrizeAmount > 500) {
            setError('Cash prize cannot exceed $500.');
          }
        }else{
          setError('Set a start time that is at least 5 minutes from now.');
        }
      } else if (hour && minute && size && mode && wager && tourneyName.length === 0) {
        console.log('hitttt');
      }
    }
  };

  

  useEffect(() => {
    handleInputChange();
  }, [tourneyName, hour, minute, size, mode, wager]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="play-vs-content">
          <div className="tdbox">
            <input
              className={`tourney-input name ${error ? 'error' : ''}`}
              type="text"
              value={tourneyName}
              onChange={(e) => setTourneyName(e.target.value)}
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Tournament Name"
            />
          </div>
          <div className="tdbox flex jc-c">
          <Dropdown
                options={variationOptions}
                selected={variation}
                setSelected={setVariation}
                label="Variation"
                className="amount"
              />
          </div>
          <div className="tdbox flex jc-c">
            <div className="date dropdown">
              <button className={`tourney-input date ${error ? 'error' : ''}`}>
                <span>{date}</span>
              </button>
            </div>
            <Dropdown 
              options={hourOptions} 
              selected={hour} 
              setSelected={setHour} 
              label=""
              className={error ? 'error' : ''}
            />
            <div>
              <svg fill="#efeff1" height="30px" viewBox="-64 0 256 256" xmlns="http://www.w3.org/2000/svg" stroke="#efeff1">
                <path d="M76.846 80a4 4 0 0 1 4.002 4.002V107.6a4.002 4.002 0 0 1-4.002 4.003H53.001A4 4 0 0 1 49 107.599V84.002A4.002 4.002 0 0 1 53.001 80h23.845zm0 64a4 4 0 0 1 4.002 4.002V171.6a4.002 4.002 0 0 1-4.002 4.003H53.001A4 4 0 0 1 49 171.599v-23.597A4.002 4.002 0 0 1 53.001 144h23.845z" fillRule="evenodd"></path>
              </svg>
            </div>
            <Dropdown 
              options={minuteOptions} 
              selected={minute} 
              setSelected={setMinute} 
              label=""
              className={error ? 'error' : ''}
            />
          </div>
          <div className="tdbox flex jc-c">
            <input
              type="checkbox"
              id="freeCashPrize"
              name="freeCashPrize"
              value="free"
              checked={freeCashPrize}
              onChange={(e) => setFreeCashPrize(e.target.checked)} 
              className='w-1rem'
            />
            <label className='ml-1rem f-875 fg-n' htmlFor="freeCashPrize">Free Cash Prize Tournament</label>
          </div>
          <div className="tdbox flex jc-c gap-input">
            <Dropdown 
              options={sizeOptions} 
              selected={size} 
              setSelected={setSize} 
              label="Room Size" 
              className='room-size'
            />
            <Dropdown 
              options={modeOptions} 
              selected={mode} 
              setSelected={setMode} 
              label="Time Control" 
              className='time-control'
            />

            {/* Conditionally render the wager input or dropdown */}
            {freeCashPrize ? (
              <div className="dropdown cash-prize-input-div tourney-input flex">
                <span className="dollar-sign">$</span>
                <input
                  type="number"
                  value={wager}
                  onChange={(e) => setWager(e.target.value)}
                  placeholder="Prize"
                  className={`cash-prize-input wager-input ${error ? 'error' : ''}`}
                />
              </div>
            ) : (
              <Dropdown 
                options={wagerOptions} 
                selected={wager} 
                setSelected={setWager} 
                label="Entry Amount" 
              />
            )}
          </div>
          <div className="tdbox flex jc-c gap-input" style={{ fontSize: '12px' }}>ELO RANGE:</div>
          <div className="tdbox flex jc-c gap-input">
          <Dropdown 
              options={minEloOptions} 
              selected={minElo} 
              setSelected={setMinElo} 
              label="" 
              className='room-size'
            />
            <div>-</div>
            <Dropdown 
              options={maxEloOptions} 
              selected={maxElo} 
              setSelected={setMaxElo} 
              label="" 
              className='time-control'
            />
          </div>
          {error && (
            <div className="club-event-field-error-component">
              <span>{error}</span>
            </div>
          )}
          <button
            type="submit"
            className={`cg-padding ${isFormValid() ? 'pointer' : 'pn0 light-op'} ${error ? 'light-op' : ''}`}
            role="button"
          >
            <span className={`${isFormValid() ? 'white' : 'play-txt'}`}>Create</span>
          </button>
        </div>
      </form>

      {showConfirmation && (
        <TournamentConfirmation
          tourneyName={tourneyName}
          variation={variation}
          hour={hour}
          minute={minute}
          wager={wager}
          freeCashPrize={freeCashPrize}
          cashPrizeAmount={freeCashPrize ? parseFloat(wager) : 0}
          minElo={minElo}
          maxElo={maxElo}
          closeModal={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default TournamentForm;