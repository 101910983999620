import React from 'react';

const AdBanner = () => {
  return (
    <div className="center banner-spc">
        <div className="main-banner-wrapper">
        <div>
            <a
            href="https://www.kickstarter.com/projects/bryghtlabs/chessup-2?ref=92dnrh"
            target="_blank"
            rel="noopener noreferrer"
            >
            <img
                alt="Marketing Banner"
                src="https://images.chesscomfiles.com/uploads/ad_image/18713.c0ab4e20.png"
            />
            </a>
        </div>
        </div>
    </div>
  );
};

export default AdBanner;