import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ingame.css';
import LeaveConfirmation from './LeaveConfirmation'; // Import the LeaveConfirmation component

const Leaderboard = ({ users, roomId }) => {
  const [authToken] = useState(localStorage.getItem('token'));
  const [showModal, setShowModal] = useState(false);
  const [tournamentIdToLeave, setTournamentIdToLeave] = useState(null);
  const navigate = useNavigate();

  const leaveTournament = async (tournamentId) => {
    try {
      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          query: `
            mutation LeaveTournament($tournamentId: ID!) {
              leaveTournament(tournamentId: $tournamentId) {
                success
                message
              }
            }
          `,
          variables: {
            tournamentId,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to leave tournament');
      }

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      navigate('/'); // Navigate to home page after successful leave
    } catch (error) {
      console.error('Error leaving tournament:', error);
    } finally {
      setShowModal(false); // Hide the modal after the operation
    }
  };

  const handleLeaveClick = (tournamentId) => {
    setTournamentIdToLeave(tournamentId); // Set the tournament ID to be used in the modal
    setShowModal(true); // Show the modal
  };

  return (
    <div className="score-background spc-btwn d-flex fd-c spc ai-c">
      <div className="standings">
        <h2 className='center'>Standings</h2>
        <div className='start-next'></div>
        <table className='w-100'>
          <tbody>
            {users.map((user, index) => (
              <tr className='standings-color' key={index}>
                <td>--</td>
                <td className='standings-username'>{user}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='lobby-leave error'>
        <button
          onClick={() => handleLeaveClick(roomId)}
        >
          Leave Tournament
        </button>
      </div>

      {/* Conditionally render the LeaveConfirmation modal */}
      {showModal && (
        <LeaveConfirmation
          onConfirm={() => leaveTournament(tournamentIdToLeave)}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Leaderboard;