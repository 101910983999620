import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/tailwind-output.css';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { UserProvider } from './contexts/UserContext'; // Import UserProvider
import { NavigationProvider } from './utils/NavigationContext';

const client = new ApolloClient({
  uri: 'https://grandhustler.com/graphql', 
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router >
        <UserProvider>
          <WebSocketProvider>
            <NavigationProvider>
              <App />
            </NavigationProvider>
          </WebSocketProvider>
        </UserProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>
);