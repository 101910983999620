import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../css/after-gameModals.css';

const AfterGameModal = ({ title, subtitle, playerData, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.style.opacity = '1';
        modalRef.current.style.transform = 'scale(1)';
        modalRef.current.style.marginLeft = '150px';
      }
    }, 10); // Slight delay to ensure the transition happens after mounting

    return () => clearTimeout(timer);
  }, []);

  return (
    <div ref={modalRef} className="board-modal-container-container">
      <div className="board-modal-component game-over-modal-container">
        <div className="game-over-modal-content">
          <div className="board-modal-header-component game-over-header-component">
            <div className="game-over-header-header">
              <div className="header-title-component">{title}</div>
              <div className="header-subtitle-component">
                <div className="header-subtitle-first-line">
                  <a href={subtitle.link} target="_blank" rel="noopener noreferrer">
                    {subtitle.text}
                  </a>
                </div>
              </div>
            </div>
            <button
              type="button"
              aria-label="Close"
              className="board-modal-header-close game-over-header-close"
              onClick={onClose}
            >
              <span className="icon-font-chess x">
                <svg
                  className="c-w cursor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  height="16"
                  width="16"
                  fill="currentColor"
                >
                  <path d="M18.442 2.442l-.884-.884L10 9.116 2.442 1.558l-.884.884L9.116 10l-7.558 7.558.884.884L10 10.884l7.558 7.558.884-.884L10.884 10l7.558-7.558z"></path>
                </svg>
              </span>
            </button>
          </div>
          <div className="game-over-player-component game-over-player-archive">
            {playerData.map((player, index) => (
              <div key={index} className={`game-over-player-player game-over-player-${player.color}`}>
                <div className="game-over-player-avatar">
                  <div className="player-avatar-component game-over-player-picture">
                    <img
                      alt={player.username}
                      src='https://www.chess.com/bundles/web/images/user-image.007dad08.svg'
                      width="56"
                      height="56"
                    />
                  </div>
                </div>
                <span className="game-over-player-username">{player.username}</span>
              </div>
            ))}
          </div>
          <div className="game-over-modal-buttons">
            <div className="game-over-buttons-component">
              <button
                className="ui_v5-button-component ui_v5-button-basic ui_v5-button-small game-over-buttons-button"
                type="button"
                onClick={() => window.location.href = '/'}
              >
                <span>Home</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Define prop types for the component
AfterGameModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired,
  playerData: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  })).isRequired,
  onClose: PropTypes.func.isRequired
};

export default AfterGameModal;