import React from "react";
import '../css/casinochip.css';

const Chip = () => {

    return(
        <div className="casino-chip five">
            <div className="horizontal stripe five"></div>
            <div className="inner-chip five">
                <div className="gh-title">
                    <div className="gh">GH</div>
                    <div className="gh-chess">CHESS</div>
                </div>
                <div className="wager-pot"></div>
            </div>
            <div className="vertical stripe five"></div>
        </div>
    )
}

export default Chip;