import React from 'react';

const UsernameInput = ({ username, setUsername, error }) => {
  const handleChange = (e) => {
    setUsername(e.target.value);
  };

  return (
    <label className="stacked samri-1ww0eyq">
      <div className="oneid-username-strength-container">
        <span className="hint samri-145194">Your username must be 3-14 characters long.</span>
      </div>
      <div className="input-wrap samri-1pryo3e">
        <div className="input-content samri-1pryo3e">
          <div className="before-icon samri-1pryo3e"></div>
          <div className="after-icon samri-1pryo3e"></div>
          <input
            id="registration-username-input"
            autoComplete="on"
            className="input spacing-expanded samri-1pryo3e"
            type="text"
            name="username"
            max="Infinity"
            data-test="register-name"
            value={username}
            onChange={handleChange}
          />
        </div>
        <div className="input-button-wrap samri-1pryo3e"></div>
      </div>
      <span className="label-content samri-5ecfln">
        <div className="label-left-wrapper samri-1pryo3e">
          <span>Username</span>
          <div className="asterisk-wrapper samri-1pryo3e">
            <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
          </div>
        </div>
      </span>
      {error && <div className="error" style={{ fontSize: '12px' }}>{error}</div>}
    </label>
  );
};

export default UsernameInput;
