import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoggedInSection from './LoggedInHeader';
import { jwtDecode } from 'jwt-decode'; // Make sure you import jwtDecode correctly
import { useQuery, gql } from '@apollo/client';
import '../css/header.css';

const GET_USER = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      id
      username
      profilePic
      balance
    }
  }
`;

const Header = ({ onToggleSidebar }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({ username: '', profilePic: '', balance: 0 });
  const [userId, setUserId] = useState('');

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId },
    skip: !userId,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      setIsLoggedIn(true);

      try {
        // Decode the token to get user information
        const decodedToken = jwtDecode(token);
        const userIdFromToken = decodedToken.userId; // Adjust based on your token structure
        setUserId(userIdFromToken);
      } catch (error) {
        console.error('Error decoding token', error);
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setUserInfo({
        username: data.getUser.username,
        profilePic: data.getUser.profilePic,
        balance: data.getUser.balance,
      });
    }
  }, [data]);

  if (loading) return <div></div>;
  if (error) return <div>Error loading user data</div>;

  return (
    <header className="top-page">
      <div className="v2">
        <nav className="nav-header">
          <div id="start" className="styles-scope hth-masthead">
            <icon-button id="guide-button" className="style-scope hth-masthead">
              <button
                id="button"
                className="style-scope hth-icon-button"
                onClick={onToggleSidebar}  // Call the toggle function on click
              >
                <hth-icon id="guide-icon" className="style-scope hth-masthead">
                  <hth-icon-shape className="style-scope hth-masthead">
                    <icon-shape className="hth-spec-icon-shape">
                      <div style={{ width: '100%', height: '100%', fill: '#fff' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false" style={{ pointerEvents: 'none', display: 'block', width: '100%', height: '100%' }}>
                          <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
                        </svg>
                      </div>
                    </icon-shape>
                  </hth-icon-shape>
                </hth-icon>
              </button>
            </icon-button>

            <hth-top-logo id="logo" className="style-scope hth-masthead">
              <div id="logo" className="hth-simple-endpoint style-scope hth-top-logo">
                <div className="style-scope hth-top-logo">
                  <hth-logo className="style-scope hth-top-logo">
                    <hth-icon id="logo-icon" className="style-scope hth-logo">
                      <hth-icon-shape className="style-scope yt-icon">
                        <icon-shape className="hth-spec-icon-shape">
                          <div className="sprite" style={{ width: '100%', height: '100%', fill: 'currentColor' }}>
                            <div className="home-header-logo"></div>
                          </div>
                        </icon-shape>
                      </hth-icon-shape>
                    </hth-icon>
                  </hth-logo>
                </div>
              </div>
            </hth-top-logo>
          </div>
          <div className="spc-between">
            <div id="end" className="hth-masthead flex">
              <div className="register-buttons">
                {isLoggedIn ? (
                  <LoggedInSection username={userInfo.username} profilePic={userInfo.profilePic} balance={userInfo.balance}/>
                ) : (
                  <>
                    <Link to="/login">
                      <button id="login-button" className="button login auth-container">
                        <span className="login-ijk2lq">Log In</span>
                      </button>
                    </Link>
                    <Link to="/register">
                      <button id="register-button" className="button register auth-container">
                        <span className="login-ijk2lq">Register</span>
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;