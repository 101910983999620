import React, { useEffect, useState } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useUser } from '../contexts/UserContext';
import Header from './Header';
import Sidebar from './SideBar';
import OwnershipFooter from './OwnershipFooter';
import Footer from './Footer';
import GameList from './GameList';
import BetSlip from './BetSlip';
import WeeklyEloBox from './WeeklyEloBox';
import AdBanner from './AdBanner';
import GameScroll from './GameHistory';
import Matches from './Scoreboard';
import Ticker from './WinnerTicker';
import TvPlayer from './TVPlayer';
import PhysicalTourneys from './PhysicalTourneys';
import Modal from './CreateGameModal';
import MessageTab from './MessageModal';
import NotificationMessage from './NotificationMessage';
import OnlineFriend from './OnlineFriends';
import '../css/chessImages.css';
import '../css/homepage.css';

const MainLayout = () => {
  const { socket, joinUserRoom } = useWebSocket();
  const user = useUser();

  const [isMessageTabOpen, setIsMessageTabOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // New state to track sidebar collapse
  const [selectedWager, setSelectedWager] = useState(null);

  useEffect(() => {
    if (socket && user) {
      joinUserRoom(user.id); // Join the room when the user is available
    }
  }, [socket, user, joinUserRoom]);

  useEffect(() => {
    if (socket) {
      socket.on('rejected', (data) => {
        setNotificationTitle('Invitation Rejected');
        setNotificationMessage('User has rejected your invite.');
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      });

      return () => {
        socket.off('rejected');
      };
    }
  }, [socket]);

  const handleToggleMessageTab = () => {
    setIsMessageTabOpen((prev) => !prev);
  };

  const handleOpenCreateGame = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitModal = () => {
    setShowModal(false);
    setNotificationTitle('Invitation Sent');
    setNotificationMessage('Invitation sent to user.');
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev); // Toggle the sidebar state
  };

  useEffect(() => {
    const mediaHolder = document.querySelector('.media-holder');
    const chessbookWrapper = document.querySelector('.chessbook-wrapper');

    const checkWidth = () => {
      if (window.innerWidth < 1330) {
        if (mediaHolder && chessbookWrapper && !chessbookWrapper.parentNode.isEqualNode(mediaHolder.parentNode)) {
          mediaHolder.parentNode.insertBefore(chessbookWrapper, mediaHolder);
        }
      } else {
        if (mediaHolder && chessbookWrapper && !mediaHolder.contains(chessbookWrapper)) {
          mediaHolder.appendChild(chessbookWrapper);
        }
      }
    };

    checkWidth(); // Initial check on mount

    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth); // Cleanup listener on unmount
    };
  }, []);

  const handleWagerSelect = (wagerData) => {
    setSelectedWager(wagerData);
  };

  return (
    <div>
      <Header onToggleSidebar={toggleSidebar} /> {/* Pass the toggleSidebar function to Header */}
      <div className="amaqao">
        <Sidebar
          isCollapsed={isSidebarCollapsed} // Pass the collapsed state to Sidebar
          onOpenCreateGame={handleOpenCreateGame}
          onToggleMessageTab={handleToggleMessageTab}
        />
        <div className="list-and-btslp-margin" style={{ marginLeft: isSidebarCollapsed ? '7.5rem' : '' }}>
          <div className="list-and-btslp">

            <div className='p-1'>
              <div className='home-greeting'>Welcome harveyspecter,</div>
                <div className='p-1'>
                  <div className='medium-title'>Elo</div>
                  <WeeklyEloBox />
              </div>
              <AdBanner />
              <div className='media-group'>
                <div className='medium-title'>Previous Games</div>
                <GameScroll />
              </div>
              <div className='media-group'>
                <div className='medium-title'>Chess Scoreboard</div>
                <Matches />
              </div>
            <div className="media-group">
              <div className='medium-title'>Winners of the Week</div>
              <Ticker />
            </div>
            </div>
            <div className="media-holder">
              <TvPlayer />
              <PhysicalTourneys />
              <OnlineFriend />
            </div>
          </div>
          <OwnershipFooter />
          <Footer />
        </div>
      </div>
      <MessageTab isMessageTabOpen={isMessageTabOpen} onClose={handleToggleMessageTab} />
      <Modal show={showModal} onClose={handleCloseModal} onSubmit={handleSubmitModal} />
      {showNotification && <NotificationMessage title={notificationTitle} message={notificationMessage} />}
    </div>
  );
};

export default MainLayout;