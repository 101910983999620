import React from 'react';
import { useNavigate } from 'react-router-dom';

const SidebarProfile = ({ isCollapsed }) => {
  const navigate = useNavigate();

  const handleSignUp = (event) => {
    event.preventDefault();
    navigate('/login');
  };

  const token = localStorage.getItem('token');
  const isLoggedIn = token !== null;  
  const usernameCase = document.querySelector('.user-profile .white');
  const username = usernameCase ? usernameCase.textContent : '';

  return (
    <div id="sidebar-profile" className="sidebar-more">
      <div style={{ background: '#242226', padding: '0 1rem', borderRadius: '0.5rem' }}>
        <span className="home-nav-2">
          <div className="home-nav-3">
            <a href="#sign-in" className="home-link" role="link" tabIndex="0" onClick={handleSignUp}>
              <div className="home-nav-4 profile_tab">
                <div class="">
                  {isLoggedIn ?
                  <img src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg" height="48px" alt="profile_picture" width="24px" style={{ borderRadius: '4px' }}></img>
                  : ''
                  }
                </div>
                <div className="home-nav-text-div" style={{ display: isCollapsed ? 'none' : '' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <span className="home-nav-text">
                        <span className="home-text">{isLoggedIn ? `${username}` : 'Sign in'}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </span>
      </div>
    </div>
  );
};

export default SidebarProfile;