import React from 'react';
import Chessboard from 'chessboardjsx';

const ReadOnlyChessboard = ({ position }) => {
  return (
    <Chessboard
      position={position} // Pass the position you want to display
      draggable={false}  // Disable piece dragging
      onDrop={() => {}}   // No action on drop
      onMouseDown={() => {}}  // No action on mouse down
      onMouseUp={() => {}}    // No action on mouse up
      onSquareClick={() => {}} // No action on square click
    />
  );
};

export default ReadOnlyChessboard;