import React from 'react';

const WithdrawalOptions = ({ setSelectedPayment }) => {
  return (
    <div className="margin">
      {/* Fast Withdrawal Section */}
      <div className="ab">
        <div className="order">
          <div className="line"></div>
          <div className="line-title">Fast Withdrawal</div>
          <div className="line"></div>
        </div>
      </div>

      {/* Payment Methods Selection */}
      <div className="payment-selection">
        {/* Debit Card */}
        <section className="payment-item" onClick={() => setSelectedPayment('DEBIT_CARD')}>
          <div className="padding" data-test-value="NEW_DEBIT_CARD">
            <div className="issuing-img">
              <div className="img-spc">
                <span>
                  <img
                    data-test-id="visaLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/visa.svg"
                    alt="Visa logo"
                    style={{ visibility: 'visible' }}
                  />
                </span>
                <span>
                  <img
                    data-test-id="mastercardLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/mastercard.svg"
                    alt="MasterCard logo"
                    style={{ visibility: 'visible' }}
                  />
                </span>
                <span>
                  <img
                    data-test-id="amexLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/amex.svg"
                    alt="Amex logo"
                    style={{ visibility: 'visible' }}
                  />
                </span>
              </div>
            </div>
            <div className="payment-style">
              <span>Debit Card</span>
            </div>
          </div>
        </section>

        {/* PayPal */}
        <section className="payment-item" onClick={() => setSelectedPayment('PAYPAL')}>
          <div className="padding" data-test-value="PAYPAL">
            <div className="issuing-img">
              <img
                className="image"
                src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/paypal.svg"
                alt="PayPal logo"
                style={{ visibility: 'visible' }}
              />
            </div>
            <div className="payment-style">
              <span>PayPal</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WithdrawalOptions;