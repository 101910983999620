import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import braintree from 'braintree-web';

const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($nonce: String!, $amount: String!) {
    createTransaction(nonce: $nonce, amount: $amount) {
      success
      transactionId
    }
  }
`;

const formatAmount = (value) => {
  // Remove non-numeric characters except decimal point
  const cleanedValue = value.replace(/[^0-9.]/g, '');
  // Add commas for thousands
  const [integerPart, decimalPart] = cleanedValue.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const CreditCard = () => {
  const [createTransaction] = useMutation(CREATE_TRANSACTION);
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [amount, setAmount] = useState(''); // State for deposit amount

  useEffect(() => {
    fetch('http://localhost:5000/api/braintree/client_token')
      .then(response => response.json())
      .then(data => {
        const clientToken = data.token;
        braintree.client.create({
          authorization: clientToken
        }, (err, clientInstance) => {
          if (err) {
            console.error(err);
            return;
          }

          braintree.hostedFields.create({
            client: clientInstance,
            styles: {
              input: {
                'font-size': '16px',
                'color': '#333'
              },
              ':focus': {
                'color': 'blue'
              }
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: '4111 1111 1111 1111'
              },
              cvv: {
                selector: '#cvv',
                placeholder: '123'
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: 'MM / YY'
              }
            }
          }, (err, hostedFieldsInstance) => {
            if (err) {
              console.error(err);
              return;
            }
            setBraintreeInstance(hostedFieldsInstance);
          });
        });
      });
  }, []);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(formatAmount(value)); // Update the state with formatted value
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Allow only numbers and decimal point
    event.target.value = value.replace(/[^0-9.]/g, '');
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!braintreeInstance || !amount) return;

    braintreeInstance.tokenize(async (err, payload) => {
      if (err) {
          console.error(err);
          return;
      }
  
      const authToken = localStorage.getItem('token'); // Retrieve token from localStorage
  
      try {
          const { data: transactionData } = await fetch('http://localhost:5000/graphql', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${authToken}`, // Include the authorization token
              },
              body: JSON.stringify({
                  query: `
                     mutation CreateTransaction($nonce: String!, $amount: String!) {
                        createTransaction(nonce: $nonce, amount: $amount) {
                          success
                          transactionId
                        }
                      }
                  `,
                  variables: {
                      nonce: payload.paymentMethodToken,  // use the payment method token here
                      amount
                  }
              }),
          }).then(res => res.json());
  
          if (transactionData.createTransaction.success) {
              alert(`Transaction successful: ${transactionData.createTransaction.transactionId}`);
          } else {
              alert('Transaction failed');
          }
      } catch (error) {
          console.error(error);
          alert('An error occurred. Please try again.');
      }
  });
  
  };

  return (
    <section className="_an _ao _ap _w _aq">
      <form id="checkout-form" className="bb _as _at _j _au _r" onSubmit={handleSubmit}>
        <div className="_g _h _a _ef _ab">
          <span className="_ef _fr _mv">We accept</span>
          <div className="gap _g _a _ef">
            <span className="_kn _a _b _k _mw _mx _ip _al _iu _iv _iw _aj _ix _iy _iz _jl _my _jc">
              <img
                data-test-id="visaLogo"
                className="image"
                src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/visa.svg"
                alt="Visa logo"
                style={{ visibility: 'visible' }}
              />
            </span>
            <span className="_kn _a _b _k _mw _mx _ip _al _iu _iv _iw _aj _ix _iy _iz _jl _my _jc">
              <img
                data-test-id="mastercardLogo"
                className="image"
                src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/mastercard.svg"
                alt="MasterCard logo"
                style={{ visibility: 'visible' }}
              />
            </span>
            <span className="_kn _a _b _k _mw _mx _ip _al _iu _iv _iw _aj _ix _iy _iz _jl _my _jc">
              <img
                data-test-id="amexLogo"
                className="image"
                src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/amex.svg"
                alt="Amex logo"
                style={{ visibility: 'visible' }}
              />
            </span>
          </div>
        </div>

        <div className="_g _bv _bw mw" style={{ marginBottom: '1rem' }}>
          <div className="_bx _by _bz _ca _ap _cb _cc _cd _ce _nq _np">
            <label htmlFor="deposit_amount" className="_g _a _nf _kz _an _aq _ao _fi">
              <span className="_ay _be _bj _ci _fc">DEPOSIT AMOUNT</span>
            </label>
            <input
              className="_a _r _cl _g _cn _at _co _au _cp _cq _cr _nm"
              type="text"
              id="deposit_amount"
              placeholder="Min $5.00"
              style={{ boxSizing: 'border-box' }}
              value={amount} // Set value from state
              onChange={handleAmountChange} // Handle change event
            />
          </div>
        </div>

        <div id="card-number" className="form-field">
          <input type="text" onInput={handleInputChange} />
        </div>
        <div id="cvv" className="form-field">
          <input type="text" onInput={handleInputChange} />
        </div>
        <div id="expiration-date" className="form-field">
          <input type="text" onInput={handleInputChange} />
        </div>

        <fieldset className="_ab">
          <div className="_a _be" data-test-id="BaseCheckbox">
            <input
              id="save_card"
              tabIndex="0"
              className="_nu _ij _p _nv"
              type="checkbox"
              data-test-id="checkbox"
              value=""
              defaultChecked
            />
            <label htmlFor="save_card" className="_a _be _cn _h _nw _dy _nx" data-test-id="CheckboxLabel">
              <div className="_cn _ny _a _k _h _cm _nz _oa _ob _oc _ai _fi _fk _mh _hc _bx">
                <svg
                  fill="currentColor"
                  version="1.1"
                  id="checkbox-tick-save_card"
                  x="0"
                  y="0"
                  viewBox="-951 532 17 17"
                  xmlSpace="preserve"
                  width="17"
                  height="17"
                  style={{ verticalAlign: 'middle', flex: '0 0 auto', fill: 'currentcolor', overflow: 'visible' }}
                >
                  <path
                    id="tick"
                    d="M-938.18 537.117a.502.502 0 00-.705.063l-4.649 5.58-2.613-2.613a.5.5 0 00-.707.707l3 3a.5.5 0 00.354.146l.022-.001a.5.5 0 00.362-.179l5-6a.499.499 0 00-.064-.703z"
                  />
                </svg>
              </div>
              <span className="_ib _by _me black">Save this card’s details</span>
            </label>
          </div>
        </fieldset>

        <button className="submit-button" type="submit">Submit Payment</button>
      </form>
    </section>
  );
};

export default CreditCard;