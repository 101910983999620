import React from 'react';
import { Link } from 'react-router-dom';
import NewsSection from './SideBarNews';
import SidebarProfile from './SideBarProfile';
import SideBarMenu from './SideBarMenu';
import '../css/sidebar.css';

const Sidebar = ({ onOpenCreateGame, onToggleMessageTab, isCollapsed }) => {
  return (
    <div className="lft-shell visible">
      <div className="lft-shell-abs">
        <div className="sidebar">
          <div className="sidebar-2">
            <div className="sidebar-border">
              <div className={`sidebar-padding ${isCollapsed ? 'w-72' : 'w-244'}`}>
                <div className="sidebar-nav-icon">
                  <SideBarMenu onOpenCreateGame={onOpenCreateGame} onToggleMessageTab={onToggleMessageTab} />
                </div>
                <NewsSection isCollapsed={isCollapsed}/>
                <SidebarProfile isCollapsed={isCollapsed} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;