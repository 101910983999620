import React, { useState, useEffect } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import ChatRoom from './chatRoom';

const client = new GraphQLClient('http://localhost:5000/graphql');

const SEARCH_USER_QUERY = gql`
  query SearchUser($username: String!) {
    searchUser(username: $username) {
      id
      username
      profilePic
    }
  }
`;

const StartNewChat = ({ onClose, onStartChat }) => {
  const [username, setUsername] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State for the selected user

  useEffect(() => {
    if (username.length >= 3) {
      setLoading(true);
      client.request(SEARCH_USER_QUERY, { username })
        .then(data => {
          setLoading(false);
          if (data.searchUser) {
            setSearchResults([data.searchUser]);
            setError(null);
          } else {
            setSearchResults([]);
            setError('Invalid Username');
          }
        })
        .catch(error => {
          setLoading(false);
          setError('An error occurred while fetching results.');
          console.error('Error:', error);
        });
    } else {
      setSearchResults([]);
      setError(null);
    }
  }, [username]);

  const handleInputChange = (e) => {
    setUsername(e.target.value);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user); // Set the selected user
    setUsername('');
  };

  const handleStartChat = () => {
    if (selectedUser) {
      onStartChat(selectedUser); // Pass the selected user to the parent component
    }
  };

  if (selectedUser) {
    return <ChatRoom user={selectedUser} />;
  }

  return (
    <div className="create-chat">
      <header className="p-m fg-0 gap-s w-full ai-c flex border-box h-275">
        <div className="pl-m mr-auto mw-100-40">
          <div className="semibold text-1 font-sans o-h c-w">
            New Chat
          </div>
        </div>
        <div className="flex fw-nw">
          <div></div>
        </div>
      </header>
      <form>
        <main>
          <div id="users" className="users-multiselect" name="users">
            <label>
              <div className="label-container interior-label p-l">
                <span className="input-boundary-box">
                  <span className="input-container stateful-input activated">
                    <span className="label-text inner-label">
                      <span>Type username</span>
                      <span>*</span>
                    </span>
                    <div className="input-bar">
                      <input
                        type="text"
                        value={username}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </span>
                </span>
              </div>
            </label>
            <div className="text-l ml-rem16">
              {loading && <div>Loading...</div>}
              {error && <div className="invalid-content text-l ml-rem16">{error}</div>}
              {searchResults.length > 0 && (
                <div className="search-results">
                  {searchResults.map(user => (
                    <div
                      key={user.username}
                      className="search-result"
                      onClick={() => handleUserClick(user)}
                    >
                      <div className="flex relative j-btwn gap-m px-rem16 py-rem8 c-w outline-offset-1 pointer" style={{ paddingRight: '1rem' }}>
                        <span className="flex ai-c gap-m shrink">
                          <span className="flex shrink-0 ai-c fg-0 jc-c lh-rem16 .text-rem20 hw-xl">
                            <span className="ai-c jc-c d-if">
                              <span className="rounded hw-rem24 d-ib o-h relative">
                                <img className="w-full h-full pointer-n" src={user.profilePic} alt={user.username} />
                              </span>
                            </span>
                          </span>
                          <span className="flex flex-col jc-c shrink py-rem6">
                            <span className="text-l">{user.username}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </main>
        <div className="bottom-buttons">
          <button
            className="button jc-c p-rem10 ai-c d-if hover button-ready"
            type="button"
            onClick={handleStartChat}
          >
            <span>Start Chat</span>
          </button>
          <button className="button jc-c p-rem10 ai-c d-if hover" type="button" onClick={onClose}>
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StartNewChat;