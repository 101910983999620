import React, { useState, useEffect } from 'react';
import StartNewChat from './startNewChat';
import '../css/messageModal.css';

const MessageTab = ({ isMessageTabOpen, onClose }) => {
  const [showStartNewChat, setShowStartNewChat] = useState(false);

  useEffect(() => {
    const line = document.getElementById('msg-line');
    const polygon = document.getElementById('msg-polygon');
    const msgTxt = document.getElementById('msg-txt');

    if (isMessageTabOpen) {
      line?.classList.add('green');
      polygon?.classList.add('green');
      msgTxt?.classList.add('green');
    } else {
      line?.classList.remove('green');
      polygon?.classList.remove('green');
      msgTxt?.classList.remove('green');
    }

    return () => {
      line?.classList.remove('green');
      polygon?.classList.remove('green');
      msgTxt?.classList.remove('green');
    };
  }, [isMessageTabOpen]);

  if (!isMessageTabOpen) return null;

  const handleStartChat = () => {
    setShowStartNewChat(true);
  };

  const handleCloseChat = () => {
    setShowStartNewChat(false);
  };

  return (
    <div className="message-chatbox">
      <div className="chat-shell">
        <div className="container .o-h">
          <nav className="r-chat-sb bs-bb d-f h-100 fd-c">
            <div className="r-chat-header ai-c d-f">
              <span className="fw-700 fs-16px lh-20px c-w font">Direct Messages</span>
              <div className="create-icon jc-c d-if" onClick={handleStartChat}>
                <span className="jc-c d-f ai-c">
                  <span className="ai-c d-f">
                    <svg
                      id="start-game"
                      className="c-w cursor-p"
                      xmlns="https://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      height="16"
                      width="16"
                      icon-name="chat-new-outline"
                      fill="currentColor"
                    >
                      <g clipPath="url(#clip0_472_1136)">
                        <path d="M17.065 2.929A9.993 9.993 0 00.122 8.443a9.883 9.883 0 001.195 6.49L.083 19.009a.73.73 0 00.9.913l4.167-1.194a9.859 9.859 0 006.45 1.142 9.992 9.992 0 005.465-16.941zm1.57 8.5a8.784 8.784 0 01-7.228 7.2 8.666 8.666 0 01-5.857-1.111l-.232-.139-3.762 1.078 1.118-3.692-.145-.238a8.656 8.656 0 01-1.172-5.893 8.752 8.752 0 1117.278 2.8v-.005zm-8.01-2.058H14v1.25h-3.375V14h-1.25v-3.376H6v-1.25h3.375V6h1.25v3.371z"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_472_1136">
                          <path d="M0 0h20v20H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </span>
              </div>
            </div>

            <div className="border"></div>

            <div className="click-tabs d-f ai-c fd-r jc-fs">
              <a className="d-f jc-c bc-t bs-bb cursor-p w-50">
                <span className="fw-600 fs-14 lh-18 c-g font hov-w trans-color">Friends</span>
              </a>
              <a className="d-f jc-c bc-t bs-bb cursor-p p-r w-50 ai-c">
                <span className="fw-600 fs-14 lh-18 c-b font">Requests</span>
              </a>
            </div>

            <div className="conversations">
              <div style={{ height: '0px' }}></div>
              <div style={{ height: '0px' }}></div>
            </div>
          </nav>

          {showStartNewChat ? (
            <StartNewChat
              onClose={handleCloseChat}
              onStartChat={(username) => console.log(`Starting chat with ${username}`)}
            />
          ) : (
            <div className="welcome d-g h-100">
              <header className="w-100 welcome-chat-header ai-c d-f bs-bb">
                <div className="fit"></div>
                <div className="flex fw-nw">
                  <div></div>
                </div>
              </header>
              <section className="as-c mh-100 ta-c oy-auto bs-bb">
                <h4 className="c-w font fw-700 d-f jc-c"></h4>
                <p className="c-w fw-400 fs-14 font"></p>
              </section>
            </div>
          )}
        </div>
      </div>

      <button onClick={onClose} className="close-btn">Close</button>
    </div>
  );
};

export default MessageTab;