import React, { useEffect, useState, useRef } from 'react';

const GameScroll = () => {
  const [peopleHistory, setPeopleHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const historyRef = useRef(null);

  useEffect(() => {
    const fetchGameHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No authentication token found');
          setLoading(false);
          return;
        }

        const response = await fetch('https://grandhustler.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Adding the Bearer token to the request headers
          },
          body: JSON.stringify({
            query: `
              query getGameHistory {
                getPlayersGameHistory {
                  didWeWin
                  pot
                  howLongAgo
                  opponentInfo {
                    id
                    username
                    profilePic
                  }
                }
              }
            `,
          }),
        });

        const data = await response.json();
        console.log('ghistory', data);

        if (data.errors) {
          throw new Error(data.errors[0].message);
        }

        const gameHistory = data.data.getPlayersGameHistory;
        setPeopleHistory(gameHistory);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching game history:', err);
        setError('Failed to fetch game history');
        setLoading(false);
      }
    };

    fetchGameHistory();
  }, []);

  const getRelativeTime = (timestamp) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    const intervals = [
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "min", seconds: 60 },
    ];

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  // Scroll to the right when the arrow is clicked
  const scrollRight = () => {
    if (historyRef.current) {
      historyRef.current.scrollTo({
        left: historyRef.current.scrollWidth,
        behavior: 'smooth', 
      });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={`game-scroll ${peopleHistory.length === 0 ? 'center' : ''}`}>
      <div
        className={`history-catalog ${peopleHistory.length === 0 ? 'empty-gamelist' : ''}`}
        ref={historyRef} // Reference the scrollable container
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }} // Enable horizontal scroll
      >
        {peopleHistory.length === 0 ? (
          <div className="no-games-message">
            You have not played any games yet.
          </div>
        ) : (
          peopleHistory.map((game, index) => (
            <div key={index} className="person-history" style={{ display: 'inline-block', marginRight: '10px' }}>
              <div className="person-mw">
                <img
                  src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg"
                  alt="user"
                  width="100px"
                  style={{ borderRadius: '8px', overflow: 'hidden' }}
                />
              </div>
              <div className="history-info">
                <div className="username">{game.opponentInfo.username}</div> {/* Add dynamic username logic if needed */}
                <div className="description fd-r">
                  <div>{game.pot} points</div>
                  <div className="dot">•</div>
                  <div>{getRelativeTime(game.howLongAgo)}</div>
                </div>
                <div className={`result ${game.didWeWin ? 'win' : 'loss error'}`}>
                  {game.didWeWin ? 'Won' : 'Lost'}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {peopleHistory.length > 0 && (
        <div className="history-arrow" onClick={scrollRight}>
          <svg
            className="arrow"
            fill="rgba(255, 255, 255, 0.1)"
            width="60px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512.003 512.003"
            xmlSpace="preserve"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <path d="M507.284,248.364L12.35,0.898C8.894-0.826,4.721-0.007,2.153,2.86c-2.56,2.884-2.867,7.125-0.759,10.351l159.07,242.79 L1.393,498.792c-2.108,3.226-1.801,7.467,0.759,10.351c1.664,1.86,4.002,2.859,6.383,2.859c1.289,0,2.594-0.29,3.814-0.896 l494.933-247.467c2.893-1.451,4.719-4.403,4.719-7.637S510.176,249.815,507.284,248.364z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default GameScroll;


