import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegistrationHeader from './RegistrationHeader';
import SmallerLogo from './SmallerLogo';
import Intro from './RegistrationIntro';

const LoginLayout = () => {
  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const handleSignUp = () => {
    navigate('/register');
  };

  return (
    <div>
      <RegistrationHeader />
      <div className="flex min-h-full">
        <div className="register card samri-vepx8a">
          <SmallerLogo />
          <div className="content samri-1kvrppx top-spacing" style={{ marginLeft: '1rem' }}>
            <div className="modal-content samri-vepx8a">
              <Intro />
              <LoginForm />
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default LoginLayout;