import React, { useState } from 'react';

const EmailInput = ({ email, setEmail, error }) => {
  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <label className="stacked samri-1ww0eyq">
      <div className="oneid-email-strength-container"></div>
      <div className="input-wrap samri-1pryo3e">
        <div className="input-content samri-1pryo3e">
          <div className="before-icon samri-1pryo3e"></div>
          <div className="after-icon samri-1pryo3e"></div>
          <input
            autoComplete="on"
            id="registration-email-input"
            className={`input spacing-expanded samri-1pryo3e ${isFocused ? 'border-active' : ''}`}
            type="email"
            name="email"
            max="Infinity"
            data-test="register-email"
            value={email}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
        <div className="input-button-wrap samri-1pryo3e"></div>
      </div>
      <span className="label-content samri-5ecfln">
        <div className="label-left-wrapper samri-1pryo3e">
          <span>Email</span>
          <div className="asterisk-wrapper samri-1pryo3e">
            <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
          </div>
        </div>
      </span>
      {error && <div className="error" style={{ fontSize: '12px' }}>{error}</div>}
    </label>
  );
};

export default EmailInput;