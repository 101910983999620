import React from 'react';
import '../css/tournamentOver.css';

const TournamentOverModal = ({ isOpen, onClose, winner, standings }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-header">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2 className='center to-header mt-1'>Tournament Complete!</h2> 
        <p className='center'>Standings</p>
      </div>
      <div className="standings-list white">
        {standings && standings.length > 0 ? (
          <ul className='standing-spacing'>
            {standings.map((player, index) => (
              <li className='center' key={index}>
                {index + 1}. {player.username} - {player.points} points
              </li>
            ))}
          </ul>
        ) : (
          <p>No standings available.</p>
        )}
      </div>
    </div>
  );
};

export default TournamentOverModal;