import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import braintree from 'braintree-web';

const CREATE_TRANSACTION = gql`
    mutation CreateTransaction($nonce: String!, $amount: String!) {
      createTransaction(nonce: $nonce, amount: $amount) {
        success
        transactionId
      }
    }
`;

const PayPalDeposit = () => {
  const [createTransaction] = useMutation(CREATE_TRANSACTION);
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [amount, setAmount] = useState('');

  useEffect(() => {
    fetch('http://localhost:5000/api/braintree/client_token')
      .then(response => response.json())
      .then(data => {
        const clientToken = data.token;

        braintree.client.create({
          authorization: clientToken
        }, (err, clientInstance) => {
          if (err) {
            console.error('Error creating Braintree client:', err);
            return;
          }

          braintree.paypalCheckout.create({
            client: clientInstance
          }, (err, paypalCheckoutInstance) => {
            if (err) {
              console.error('Error creating PayPal instance:', err);
              return;
            }

            setBraintreeInstance(paypalCheckoutInstance);
          });
        });
      });
  }, []);

  const formatAmount = (value) => {
    // Remove any characters that are not digits or a decimal point
    let cleanedValue = value.replace(/[^0-9.]/g, '');
    
    // Ensure only two decimal places are allowed
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      cleanedValue = `${parts[0]}.${parts[1]}`;
    } else if (parts[1] && parts[1].length > 2) {
      cleanedValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }

    // Add commas for thousands
    const formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    // If there's a decimal part, add it back
    return parts[1] ? `${formattedValue}.${parts[1]}` : formattedValue;
  };

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatAmount(inputValue);
    setAmount(formattedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!braintreeInstance || !amount) {
      return;
    }

    // Convert the formatted amount back to a number for processing
    const numericAmount = parseFloat(amount.replace(/,/g, ''));

    // Trigger the PayPal flow
    braintreeInstance.tokenize({
      flow: 'checkout',
      amount: numericAmount,
      currency: 'USD',
    }, (err, payload) => {
      if (err) {
        console.error('Error requesting payment method:', err);
        return;
      }

      // Send the nonce and amount to your server
      createTransaction({
        variables: { token: payload.paymentMethodToken, amount: numericAmount.toString() }
      }).then(({ data }) => {
        if (data.createTransaction.success) {
          alert(`Transaction successful: ${data.createTransaction.transactionId}`);
        } else {
          alert('Transaction failed');
        }
      }).catch(error => {
        console.error(error);
      });
    });
  };

  return (
    <section className="_an _ao _ap _w _aq">
      <form id="paypal-form" className="bb _as _at _j _au _r" onSubmit={handleSubmit}>
        <div className="_g _h _a _ef _ab">
          <span className="_ef _fr _mv">We accept PayPal</span>
        </div>

        <div className="_g _bv _bw mw" style={{ marginBottom: '1rem' }}>
          <div className="_bx _by _bz _ca _ap _cb _cc _cd _ce _nq _np">
            <label htmlFor="deposit_amount" className="_g _a _nf _kz _an _aq _ao _fi">
              <span className="_ay _be _bj _ci _fc">DEPOSIT AMOUNT</span>
            </label>
            <input
              className="_a _r _cl _g _cn _at _co _au _cp _cq _cr _nm"
              type="text"
              id="deposit_amount"
              placeholder="Min $5.00"
              value={amount}
              onChange={handleAmountChange}
            />
          </div>
        </div>

        <button className="submit-button" type="submit" disabled={!braintreeInstance}>Pay with PayPal</button>
      </form>
    </section>
  );
};

export default PayPalDeposit;