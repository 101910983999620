import React from "react";

const RoundsSign = ({ currentRound, totalRounds }) => {
  const displayRound = Math.min(currentRound, totalRounds);

    return (
      <div className="lobby-clock">
        <div className="nxt-round-msg">Round</div>
        <div className="volume">
          <div className="num-joined">{displayRound}</div>
          <div className="cap">/</div>
          <div className="cap num">{totalRounds}</div>
        </div>
      </div>
    );
  };

  export default RoundsSign;