import React from 'react';

const Standings = ({ users }) => {
  
  return (
    <div className="standings">
      <h2 className='center'>Standings</h2>
      <div className='start-next'></div>
      <table className='w-100'>
        <tbody>
          {users.map((user, index) => (
            <tr className='standings-color' key={index}> {/* Use index as the key if no unique ID is available */}
              <td>#{index + 1}</td>
              <td className='standings-username'>{user.username}</td>
              <td className='standings-points'>{user.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Standings;