import React from 'react';
import '../css/scoreboard.css';

const formatTime = (time) => {
  if (typeof time === 'number') {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  return time; // Fallback for invalid time values
};

const Scoreboard = ({ initialTime, blackTime, whiteTime, countdownMessage, playerColor, position }) => {
  const isBlackTimeError = blackTime < 20;
  const isWhiteTimeError = whiteTime < 20;

  const displayBlackTime = blackTime !== undefined ? formatTime(blackTime) : initialTime;
  const displayWhiteTime = whiteTime !== undefined ? formatTime(whiteTime) : initialTime;
  
  return (
    <div>
      {position === 'top' && (
        <div id="board-layout-player-top" className="casing board-layout-player board-layout-top">
          {/* Top player scoreboard */}
          <div className="player-top player-theatre">
            {/* Player avatar, name, and clock */}
            <div className="player-avatar-component player-avatar"></div>
            <div className="player-tagline">
              <a className="user-name-component user-tagline-compact-name"></a>
            </div>
            <div className={`clock-component clock-top clock-black ${isBlackTimeError ? 'bck-error' : ''}`}>
              <span className="clock-time-monospace">{displayBlackTime}</span>
            </div>
            {playerColor === 'black' && countdownMessage && <span>{countdownMessage}</span>}
          </div>
        </div>
      )}

      {position === 'bottom' && (
        <div id="board-layout-player-bottom" className="casing board-layout-player board-layout-bottom">
          {/* Bottom player scoreboard */}
          <div className="player-bottom player-theatre">
            <div className="player-avatar-component player-avatar"></div>
            <div className="player-tagline">
              <a className="user-name-component user-tagline-compact-name"></a>
            </div>
            <div className={`clock-component clock-bottom clock-white ${isWhiteTimeError ? 'bck-error' : ''}`}>
              <span className="clock-time-monospace">{displayWhiteTime}</span>
            </div>
            {playerColor === 'white' && countdownMessage && <span>{countdownMessage}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Scoreboard;