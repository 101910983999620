import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { useMutation, useQuery, gql, useApolloClient } from '@apollo/client';

import Header from './Header';
import Sidebar from './SideBar';
import ChessboardComponent from './ChessBoard';
import Chip from './CasinoChip';
import Scoreboard from './InGameScoreboard';
import RoundsSign from './TourneyRoundSign';
import Standings from './Standings';
import WhiteKing from '../assets/images/capturedWK.svg';
import WhiteQueen from '../assets/images/capturedWQ.svg';
import WhiteRook from '../assets/images/capturedWR.svg';
import WhiteBishop from '../assets/images/capturedWB.svg';
import WhiteKnight from '../assets/images/capturedWN.svg';
import WhitePawn from '../assets/images/capturedWP.svg';
import BlackKing from '../assets/images/capturedBK.svg';
import BlackQueen from '../assets/images/capturedBQ.svg';
import BlackRook from '../assets/images/capturedBR.svg';
import BlackBishop from '../assets/images/capturedBB.svg';
import BlackKnight from '../assets/images/capturedBN.svg';
import BlackPawn from '../assets/images/capturedBP.svg';
import NotificationMessage from './NotificationMessage';

import { useWebSocket } from '../contexts/WebSocketContext';
import '../css/react.css';
import '../css/ingame.css';

const GameLayout = () => {
  const location = useLocation();
  const { roomId, gameRoomId, flip, time, tournament, currentRound: initialRound, totalRounds, initialStandings, variation, player1Username, player2Username, player1Id, player2Id } = location.state || {};
  const { socket } = useWebSocket();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Collapsed state
  const [countdownMessage, setCountdownMessage] = useState('');
  const [playerColor, setPlayerColor] = useState('white');
  const [blackTime, setBlackTime] = useState(time);
  const [whiteTime, setWhiteTime] = useState(time);
  const [standings, setStandings] = useState(initialStandings || []); 
  const [currentRound, setCurrentRound] = useState(initialRound);  
  const [notification, setNotification] = useState(null);
  const [moveHistory, setMoveHistory] = useState([]);
  const [capturedWhitePieces, setWhiteCapturedPieces] = useState([]);
  const [capturedBlackPieces, setBlackCapturedPieces] = useState([]);
  const [followButtonText, setFollowButtonText] = useState({});
  const client = useApolloClient();
  const idToSend = tournament ? gameRoomId : roomId;

  useEffect(() => {
    if (socket && idToSend) {
      socket.emit('start_game', idToSend);

      const handleCountdown = (remainingTime) => {
        setCountdownMessage(`Auto abort in ${remainingTime} seconds for ${playerColor}...`);
      };

      const handleCancelCountdown = () => {
        setCountdownMessage('');
        setPlayerColor('black');
      };

      const handleGameClockUpdate = (player, remainingTime) => {
        if (player === 'black') {
          setBlackTime(remainingTime);
        } else if (player === 'white') {
          setWhiteTime(remainingTime);
        }
      };

      socket.on('auto_abort_countdown', handleCountdown);
      socket.on('cancel_auto_abort_countdown', handleCancelCountdown);
      socket.on('game_clock_black', (remainingTime) => handleGameClockUpdate('black', remainingTime));
      socket.on('game_clock_white', (remainingTime) => handleGameClockUpdate('white', remainingTime));
      socket.on('receive_move', (moveData) => {
        addMoveToHistory(moveData.move);
      });
      socket.on('bye-notification', (notificationData) => {
        setNotification(notificationData);  
        setTimeout(() => {
          setNotification(null);
        }, 10000);
      });

      socket.on('live_standings', (standingsData) => {
        setStandings(standingsData.standings);
        setCurrentRound(standingsData.currentRound);
      });

      return () => {
        socket.off('auto_abort_countdown', handleCountdown);
        socket.off('cancel_auto_abort_countdown', handleCancelCountdown);
        socket.off('game_clock_black', handleGameClockUpdate);
        socket.off('game_clock_white', handleGameClockUpdate);
        socket.off('live_standings');
        socket.off('bye-notification'); 
      };
    }
  }, [socket, idToSend]);

  useEffect(() => {
    const playerId = flip ? player1Id : player2Id;
  
    if (playerId) {
      // Check if the current user is following the determined player (either player1 or player2)
      checkFollowingStatus(playerId).then((isFollowing) => {
        setFollowButtonText((prev) => ({
          ...prev,
          [playerId]: isFollowing,
        }));
      });
    }
  }, [flip, player1Id, player2Id]);

  const addMoveToHistory = debounce((move) => {
      setMoveHistory((prevHistory) => {
          return [...prevHistory, move]; 
      });
  }, 300);

  const handleNewMove = (moveData) => {
    setMoveHistory((prevHistory) => 
      [...prevHistory, moveData.move]);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const pieceImages = {
    wk: WhiteKing,
    wq: WhiteQueen,
    wr: WhiteRook,
    wb: WhiteBishop,
    wn: WhiteKnight,
    wp: WhitePawn,
    bk: BlackKing,
    bq: BlackQueen,
    br: BlackRook,
    bb: BlackBishop,
    bn: BlackKnight,
    bp: BlackPawn,
  };

  const FOLLOW_USER = gql`
    mutation FollowUser($followingId: ID!) {
      followUser(followingId: $followingId) {
        id
        followingId
        followerId
        createdAt
      }
    }
  `;

  const UNFOLLOW_USER = gql`
    mutation UnfollowUser($followingId: ID!) {
      unfollowUser(followingId: $followingId) {
        id
        followingId
        followerId
        deletedAt
      }
    }
  `;


    const IS_FOLLOWING = gql`
    query IsFollowing($followingId: ID!) {
      isFollowing(followingId: $followingId)
      }
  `;

  const [followUser] = useMutation(FOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Set up the unfollow user mutation
  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Check if the user is following a specific user
  const checkFollowingStatus = async (userId) => {
    try {
      const { data } = await client.query({
        query: IS_FOLLOWING,
        variables: { followingId: userId },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
          },
        },
      });
      console.log('is following', data.isFollowing);
      return data.isFollowing;
    } catch (error) {
      console.error('Error checking following status:', error.message);
      return false;
    }
  };

  const handleFollowUnfollow = async (userId, event) => {
    event.preventDefault(); // Prevent default behavior if it's in a form
    const isFollowing = followButtonText[userId]; // Determine follow state for the user
  
    try {
      if (isFollowing) {
        
        const { data } = await unfollowUser({ variables: { followingId: userId } });
        console.log('Unfollow successful:', data.unfollowUser);
        
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: false
        }));
      } else {
        // Execute follow mutation
        const { data } = await followUser({ variables: { followingId: userId } });
        console.log('Follow successful:', data.followUser);
        
        // Update the state to reflect that the user is now followed
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: true
        }));
      }
    } catch (error) {
      console.error('Follow/Unfollow error:', error.message);
    }
  };

  return (
    <div className='inherit'>
      <Header onToggleSidebar={toggleSidebar}  />
      <div className="flex inherit">
        <Sidebar isCollapsed={isSidebarCollapsed} /> 
        <div className={`flex board-standing-container fd-c ${isSidebarCollapsed ? 'expanded-content' : ''} ${tournament ? '' : 't-0'}`}>
          {tournament && <RoundsSign currentRound={currentRound} totalRounds={totalRounds} />}
          <div className="history-parent in-game">
            <div className="move-history flex"></div>
          </div>
          <div id="board-layout-main" className="board-layout-main d-flex mt-3">
            <div className="outer-chessboard">
              <ChessboardComponent 
              flip={flip} 
              roomId={roomId} 
              tournament={tournament} 
              gameRoomId={gameRoomId}
              variation={variation}
              onMoveMade={handleNewMove} 
              setWhiteCapturedPieces={setWhiteCapturedPieces}
              setBlackCapturedPieces={setBlackCapturedPieces}
              />
            </div>
            <div className={`score-background spc-btwn d-flex fd-c spc ${flip ? 'column-reverse' : ''}`}>
            <div className="scoreboard-section">
              {flip ? (
                <>
                  <div className="piece-basket">
                    {capturedWhitePieces.map((piece, index) => (
                      <div key={index} className={`piece w${piece}`}>
                        <img src={pieceImages[`w${piece}`]} alt={`captured white ${piece}`} width={24} />
                      </div>
                    ))}
                  </div>
                <Scoreboard 
                    initialTime={time}
                    blackTime={blackTime} 
                    whiteTime={whiteTime} 
                    countdownMessage={countdownMessage} 
                    playerColor={playerColor} 
                    position="top"
                  />
                  <div className="ig-profile bottom flex fg-0">
                    <img src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg" alt={player1Username} width={24} style={{ borderRadius: '4px' }} />
                    <div className="ingame-username">{player2Username}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="ig-profile top flex fg-0">
                    <img src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg" alt={player1Username} width={24} style={{ borderRadius: '4px' }} />
                    <div className="ingame-username">{player2Username}</div>
                    <button 
                      className='add-space' 
                      onClick={(e) => handleFollowUnfollow(player2Id, e)}
                    >
                      <span className='add-svg'> 
                        {followButtonText[player2Id] ? '-' : '+'} 
                      </span> 
                    </button>
                  </div>
                  <Scoreboard 
                    initialTime={time}
                    blackTime={blackTime} 
                    whiteTime={whiteTime} 
                    countdownMessage={countdownMessage} 
                    playerColor={playerColor} 
                    position="top"
                  />
                  <div className="piece-basket">
                    {capturedWhitePieces.map((piece, index) => (
                      <div key={index} className={`piece w${piece}`}>
                        <img src={pieceImages[`w${piece}`]} alt={`captured white ${piece}`} width={24} />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
              {tournament && <div className="standings-section"><Standings users={standings} /></div>}
              <table className="movehistory move-body">
                <tbody className='history-scroll'>
                  {moveHistory.map((move, index) => (
                    // For every two moves (index % 2 === 0), display a new row
                    (index % 2 === 0) ? (
                      <tr key={index}>
                        <td className={`pl-left1 left-curve ${Math.floor(index / 2) % 2 === 0 ? 'move-bck' : ''}`}>
                          {Math.floor(index / 2) + 1}.
                        </td> {/* Display the turn number */}
                        
                        <td className={`white f-12 ${Math.floor(index / 2) % 2 === 0 ? 'move-bck' : ''}`}>
                          {move.to}
                        </td> {/* Display white's move */}
                        
                        <td className={`white f-12 right-curve ${Math.floor(index / 2) % 2 === 0 ? 'move-bck' : ''}`}>
                          {moveHistory[index + 1] ? moveHistory[index + 1].to : ''}
                        </td> {/* Display black's move if available */}
                      </tr>
                    ) : null
                  ))}
                </tbody>
              </table>
              <div className="scoreboard-section">
              {flip ? (
                <>
                  <div className="ig-profile top flex fg-0">
                    <img src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg" alt={player2Username} width={24} style={{ borderRadius: '4px' }} />
                    <div className="ingame-username">{player1Username}</div>
                    <button 
                      className='add-space' 
                      onClick={(e) => handleFollowUnfollow(player1Id, e)}
                    >
                      <span className='add-svg'> 
                        {followButtonText[player1Id] ? '-' : '+'} {/* Toggle text based on follow state */}
                      </span> 
                    </button>
                  </div>
                  <Scoreboard 
                    initialTime={time}
                    blackTime={blackTime} 
                    whiteTime={whiteTime} 
                    countdownMessage={countdownMessage} 
                    playerColor={playerColor} 
                    position="bottom"
                  />
                  <div className="piece-basket">
                    {capturedBlackPieces.map((piece, index) => (
                      <div key={index} className={`piece b${piece}`}>
                        <img src={pieceImages[`b${piece}`]} alt={`captured black ${piece}`} width={24} />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="piece-basket">
                    {capturedBlackPieces.map((piece, index) => (
                      <div key={index} className={`piece b${piece}`}>
                        <img src={pieceImages[`b${piece}`]} alt={`captured black ${piece}`} width={24} />
                      </div>
                    ))}
                  </div>
                  <Scoreboard 
                    initialTime={time}
                    blackTime={blackTime} 
                    whiteTime={whiteTime} 
                    countdownMessage={countdownMessage} 
                    playerColor={playerColor} 
                    position="bottom"
                  />
                  <div className="ig-profile bottom flex fg-0">
                    <img src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg" alt={player2Username} width={24} style={{ borderRadius: '4px' }} />
                    <div className="ingame-username">{player1Username}</div>
                  </div>
                </>
              )}
              </div>
            </div>
          </div>
          <Chip />
        </div>
      </div>
      {notification && <NotificationMessage title={notification.title} message={notification.message} />}
    </div>
  );
};

export default GameLayout;