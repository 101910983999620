import React, { useState } from 'react';
import DebitCard from './DebitCard';
import CreditCard from './CreditCard';
import PayPalDeposit from './PaypalDeposit';
import PaymentOptions from './PaymentOptions';

const WalletDepositPage = ({ goBack }) => {
  const [selectedPayment, setSelectedPayment] = useState(null);

  return (
    <div>
      {/* Header Section */}
      <div className="flex header">
        <button type="button" tabIndex="0" className="previous" onClick={goBack}>
          <svg
            fill="currentColor"
            viewBox="0 0 64 64"
            width="16px"
            className="svg-icon"
          >
            <title>Back</title>
            <path d="M36.998 53.995L16 32.998 36.998 12l6.306 6.306L28.61 33l14.694 14.694L36.998 54v-.005Z" />
          </svg>
          <span>Wallet</span>
          <span>/</span>
          <span className="white">Deposit Doubloons</span>
        </button>
      </div>

      {/* Content Section */}
      <div className="content scroll">
        {selectedPayment === 'DEBIT_CARD' ? (
          <DebitCard />
        ) : selectedPayment === 'CREDIT_CARD' ? (
          <CreditCard />
        ) : selectedPayment === 'PAYPAL' ? (
          <PayPalDeposit />
        ) : (
          <PaymentOptions setSelectedPayment={setSelectedPayment} />
        )}
      </div>
    </div>
  );
};

export default WalletDepositPage;