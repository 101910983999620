import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery, gql, useApolloClient } from '@apollo/client';
import { useUsers } from '../hooks/useUsers'; // Import the custom hook
import { clickSearchBox, closeResultBox } from '../utils/searchUtils'; // Import the functions

export const FOLLOW_USER = gql`
  mutation FollowUser($followingId: ID!) {
    followUser(followingId: $followingId) {
      id
      followingId
      followerId
      createdAt
    }
  }
`;

export const UNFOLLOW_USER = gql`
  mutation UnfollowUser($followingId: ID!) {
    unfollowUser(followingId: $followingId) {
      id
      followingId
      followerId
      deletedAt
    }
  }
`;

// Define the is following query
export const IS_FOLLOWING = gql`
  query IsFollowing($followingId: ID!) {
    isFollowing(followingId: $followingId)
    }
`;

const FullSearchBar = () => {
  const searchBoxRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [followButtonText, setFollowButtonText] = useState({});
  const { loading, users, error } = useUsers(); // Use the custom hook
  const client = useApolloClient();

  // Set up the follow user mutation
  const [followUser, { loading: followLoading, error: followError }] = useMutation(FOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Set up the unfollow user mutation
  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Check if the user is following a specific user
  const checkFollowingStatus = async (userId) => {
    try {
      const { data } = await client.query({
        query: IS_FOLLOWING,
        variables: { followingId: userId },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
          },
        },
      });
      console.log(data.isFollowing);
      return data.isFollowing;
    } catch (error) {
      console.error('Error checking following status:', error.message);
      return false;
    }
  };

  useEffect(() => {
    const initializeFollowButtonText = async () => {
      const newFollowButtonText = {};
      for (const user of users) {
        const isFollowing = await checkFollowingStatus(user.id);
        newFollowButtonText[user.id] = isFollowing;
      }
      setFollowButtonText(newFollowButtonText);
    };

    if (users.length > 0) {
      initializeFollowButtonText();
    }
  }, [users, client]);

  // Filter users based on search term
  useEffect(() => {
    if (users) {
      const results = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(results);
    }
  }, [users, searchTerm]);

  // Handle click and close actions with a delay
  useEffect(() => {
    if (searchBoxRef.current) {
      // Delay the activation
      const timeoutId = setTimeout(() => {
        const searchBoxContainer = searchBoxRef.current.closest('.search-box-container');
        if (searchBoxContainer) {
          searchBoxContainer.classList.add('active');
          const cleanUpClickSearchBox = clickSearchBox(searchBoxRef.current);

          // Close result box if clicked outside
          const handleClickOutside = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
              closeResultBox();
            }
          };

          document.addEventListener('mousedown', handleClickOutside);

          // Cleanup function
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            cleanUpClickSearchBox(); // Cleanup click search box listeners
          };
        }
      }, 100);

      // Cleanup timeout
      return () => clearTimeout(timeoutId);
    }
  }, [searchBoxRef]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    const resultBox = searchBoxRef.current.querySelector('#typeaheadDropdown');
    if (resultBox) {
      resultBox.style.display = event.target.value ? 'block' : 'none';
    }
  };

  const handleFollowUnfollow = async (userId, event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const isFollowing = followButtonText[userId];
    
    try {
      if (isFollowing) {
        // Execute unfollow mutation
        const { data } = await unfollowUser({ variables: { followingId: userId } });
        console.log('Unfollow successful:', data.unfollowUser);
        
        // Update the state to reflect that the user is now not followed
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: false
        }));
      } else {
        // Execute follow mutation
        const { data } = await followUser({ variables: { followingId: userId } });
        console.log('Follow successful:', data.followUser);
        
        // Update the state to reflect that the user is now followed
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: true
        }));
      }
    } catch (error) {
      console.error('Follow/Unfollow error:', error.message);
    }
  };

  if (loading) return <div></div>;
  if (error) return <div></div>;

  return (
    <div className="search-box-container" ref={searchBoxRef}>
      <div className="contents">
        <hth-search-large className="nd">
          <div className="hth-search-bar">
            <div>
              <form className="hth-search-form" autoComplete="off" role="search" style={{ overflow: 'visible' }}>
                <hth-search-input className="search-input" style={{ width: '100%' }} type="search" placeholder="">
                  <label style={{ '--left-label-position': '0px' }}>
                    <div className="label-container without-label">
                      <span id="icon-container">
                        <svg role="img" id="search-icon" height="16" width="16" aria-hidden="true" viewBox="0 0 16 16">
                          <path d="M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5zM.25 7a6.75 6.75 0 1 1 12.096 4.12l3.184 3.185a.75.75 0 1 1-1.06 1.06L11.304 12.2A6.75 6.75 0 0 1 .25 7z"></path>
                        </svg>
                      </span>
                      <span className="input-container">
                        <input
                          id="search-box"
                          type="text"
                          autoComplete="off"
                          placeholder=""
                          value={searchTerm}
                          onChange={handleChange}
                        />
                        <div className="result-box-parent">
                          <div id="typeaheadDropdown" className="result-box">
                            {filteredUsers.length > 0 ? (
                              filteredUsers.map(user => (
                                <div key={user.id} className="result-item">
                                  <img className='result-item-pic' src={user.profilePic} alt={user.username} />
                                  <span>{user.username}</span>
                                  <div className='follow-button-container'>
                                    <button 
                                      className={`follow-button ${followButtonText[user.id] ? 'following-button' : ''}`} 
                                      onClick={(event) => handleFollowUnfollow(user.id, event)}
                                    >
                                      {followButtonText[user.id] ? 'Following' : 'Follow'}
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results">No results found</div>
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </label>
                </hth-search-input>
              </form>
            </div>
          </div>
        </hth-search-large>
      </div>
    </div>
  );
};

export default FullSearchBar;