import React from 'react';


const Ticker = () => {
  const users = [
    { username: 'RachelZane', amount: '200,000', date: '11-27' },
    { username: 'LouisLitt', amount: '100,000', date: '11-28' },
    { username: 'MikeRoss', amount: '50,000', date: '11-29' },
    { username: 'HarveySpecter', amount: '205,000', date: '11-30' }
  ];

  return (
    <div className="ticker-container">
      <div className="ticker">
        {users.map((user, index) => (
          <div key={index} className="ticker-item">
            <span className="date">{user.date}.) </span>
            <span className="winner-username">{user.username}</span> -
            <span className="amount"> {user.amount}</span> 
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ticker;