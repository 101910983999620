import React, { useState } from 'react';

const PasswordInput = ({ password, setPassword, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // State to track focus

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <label className="stacked samri-1ww0eyq">
      <div className="oneid-password-strength-container"></div>
      <div className="input-wrap samri-1pryo3e">
        <div className="input-content samri-1pryo3e">
          <div className="before-icon samri-1pryo3e"></div>
          <div className="after-icon samri-1pryo3e"></div>
          <input
            id="registration-password-input"
            autoComplete="on"
            className={`input spacing-expanded samri-1pryo3e ${isFocused ? 'border-active' : ''}`}
            type={showPassword ? 'text' : 'password'}
            name="password"
            max="Infinity"
            data-test="register-password"
            value={password}
            onChange={handlePasswordChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <div className="view-password samri-1pryo3e">
            <button
              type="button"
              aria-label="Reveal password"
              className="samri-1pryo3e"
              id="view-register-password"
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="#C5C5C5"
                  d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="input-button-wrap samri-1pryo3e"></div>
      </div>
      <span className="label-content samri-5ecfln">
        <div className="label-left-wrapper samri-1pryo3e">
          <span>Password</span>
          <div className="asterisk-wrapper samri-1pryo3e">
            <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
          </div>
        </div>
      </span>
      {error && <div className="error" style={{ fontSize: '12px' }}>{error}</div>}
    </label>
  );
};

export default PasswordInput;