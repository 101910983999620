// NotificationBadge.jsx
import React from 'react';

const NotificationBadge = ({ count }) => {
  return (
    count > 0 && (
      <div className="notification" style={{ textOverflow: 'unset', color: 'rgb(255, 255, 255)'}}>
        <span className="notification-text"></span>
      </div>
    )
  );
};

export default NotificationBadge;