import React, { useState, forwardRef } from 'react';

const Dropdown = forwardRef(({ options, selected, setSelected, label, className }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault(); // Prevent form submission if the button is inside a form
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, e) => {
    e.preventDefault(); // Prevent form submission if the option is inside a form
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={`tourney-input ${className}`}
        onClick={toggleDropdown}
        type="button" // Ensure this does not submit the form
      >
        <span>{selected || label}</span>
      </button>
      {isOpen && (
        <div className="dropdown-menu jc-c flex fd-c space">
          {options.map((option, index) => (
            <button
              key={index}
              className={`tourney-choice ${selected === option ? 'selected' : ''}`}
              onClick={(e) => handleOptionClick(option, e)}
              type="button" // Ensure this does not submit the form
            >
              <span>{option}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
});

export default Dropdown;
