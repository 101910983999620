import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './DropdownOptions';
import { useWebSocket } from '../contexts/WebSocketContext';

const PlayVS = ({ username, mutual, onSubmit }) => {
  const [wager, setWager] = useState('50,000');
  const [mode, setMode] = useState('5 Min');
  const [variation, setVariation] = useState('Standard');
  const [color, setColor] = useState('random');

  const { socket } = useWebSocket();

  const variationOptions = ['Standard', 'King of the Hill'];
  const wagerOptions = ['0', '10,000', '50,000', '100,000', '200,000'];
  const modeOptions = ['1 Min', '2 | 1', '3 Min', '3 | 2', '5 Min', '10 Min'];

  const determineGameMode = (time) => {
    if (time === '10 Min') {
      return 'Classical';
    } else if (['3 Min', '3 | 2', '5 Min'].includes(time)) {
      return 'Blitz';
    } else if (['1 Min', '2 | 1'].includes(time)) {
      return 'Bullet';
    } else {
      return 'Unknown';
    }
  };

  const handleClick = async () => {
    const hasSufficientFunds = await checkFunds(wager);
    if (hasSufficientFunds) {
      putMoneyInPot(wager);
      const gameType = determineGameMode(mode);
      const invitationData = {
        receiverId: mutual.id,
        variation: variation,
        time: mode,
        wager: cleanWagerInput(wager),
        gameType,
        color,
      };

      socket.emit('send_invitation', invitationData);
      await sendInvitation(invitationData);
      onSubmit();
    } else {
      console.log('insufficient funds');
    }
  };

  async function checkFunds(wager) {
    try {
      const token = localStorage.getItem('token');
      const cleanedWager = cleanWagerInput(wager);

      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager }
        }),
        credentials: 'include'
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error('Error checking funds:', error);
      return false;
    }
  }

  async function putMoneyInPot(wager) {
    try {
        const token = localStorage.getItem('token');
        const cleanedWager = cleanWagerInput(wager);
        const freeCashPrize = false;

        if (!token) {
            throw new Error('No token found in localStorage');
        }
    
        const response = await fetch('https://grandhustler.com/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query: `
                    mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
                        putMoneyInPot(wager: $wager, join: $join)
                    }
                `,
                variables: { 
                    wager: cleanedWager,
                    join: freeCashPrize ? true : false,
                }
            })
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
        }
    
        const data = await response.json();
        if (data.errors) {
            throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
        }
        
        return data;
    } catch (error) {
        console.error('Error putting money in pot:', error);
    }
  }

  async function sendInvitation(invitationData) {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation CreateInvitation($input: InvitationInput!) {
                createInvitation(input: $input) {
                    id
                    senderId
                    receiverId
                    status
                    time
                    wager
                    gameType
                    color
                    roomId
                }
            }
          `,
          variables: { input: invitationData }
        }),
        credentials: 'include'
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  }

  function cleanWagerInput(input) {
    return parseFloat(input.replace(/,/g, ''));
  }

  return (
    <div className="play-vs-component tab-content-component">
      <div className="user-avatar play-vs-avatar">
        <img
          alt={username}
          className="user-avatar-image"
          height="80"
          src={mutual.profilePic}
          width="80"
          srcSet={`${mutual.profilePic} 2x`}
          data-visible="true"
        />
      </div>

      <div className="user-tagline play-vs-tagline">
        <span className="user-username-component user-theme-high user-tagline-username">
          {username}
        </span>
      </div>

      <div className="play-vs-content">
        <div className="create-game-component">
              <Dropdown
                options={variationOptions}
                selected={variation}
                setSelected={setVariation}
                label="Variation"
                className="variation"
              />
          <div className="flex selector-container">
            <div className="new-game-type-selector wager-amount dropdown friend">
              <Dropdown
                options={wagerOptions}
                selected={wager}
                setSelected={setWager}
                label="Wager Amount"
                className="amount"
              />
            </div>
            <div className="new-game-time-selector game-mode friend">
              <Dropdown
                options={modeOptions}
                selected={mode}
                setSelected={setMode}
                label="Time Control"
                className="mode"
              />
            </div>
          </div>

          <div className="custom-game-fields">
            <div className="custom-game-field-component">
              <label htmlFor="color-selector fg-0" style={{ flexGrow: 0 }}>I play as</label>
              <button className={`color-selector-option color-selector-white ${color === 'white' ? 'color-selected' : ''}`} data-color="white" onClick={() => setColor('white')}>
                <span className="icon-font-chess color-selector-icon white-king-stroke"></span>
              </button>
              <button className={`color-selector-option color-selector-random ${color === 'random' ? 'color-selected' : ''}`} data-color="random" onClick={() => setColor('random')}>
                <span className="icon-font-chess color-selector-icon question-mark"></span>
              </button>
              <button className={`color-selector-option color-selector-black ${color === 'black' ? 'color-selected' : ''}`} data-color="black" onClick={() => setColor('black')}>
                <span className="icon-font-chess color-selector-icon black-king-stroke"></span>
              </button>
            </div>
          </div>

          <button type="submit" className="cg-padding pe-a" role="button" onClick={handleClick}>
            <span>Invite</span>
          </button>
        </div>
      </div>
    </div>
  );
};

PlayVS.propTypes = {
  username: PropTypes.string.isRequired,
  mutual: PropTypes.shape({
    profilePic: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PlayVS;