import React, { useState } from "react";
import '../css/physicalTourney.css';
import { useQuery, gql } from '@apollo/client';
import PostPhysicalTournamentForm from "./postPhysicalTournament";
import PhysicalTournamentModal from "./PhysicalTourneyModal";

const GET_PHYSICAL_TOURNAMENTS = gql`
  query GetPhysicalTournaments($state: String!) {
    getPhysicalTournaments(state: $state) {
      title
      street
      city
      date
      hour
      minute
      bio
      type
    }
  }
`;

const states = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

const PhysicalTourneys = () => {
  const [state, setState] = useState('CA'); 
  const [selected, setSelected] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false); // State to manage form visibility
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedTournament, setSelectedTournament] = useState(null);
  const { loading, error, data } = useQuery(GET_PHYSICAL_TOURNAMENTS, {
    variables: { state },
  });

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleMouseEnter = (item) => {
    if (selected !== item) {
      setSelected(item);
    }
  };

  const handleMouseLeave = () => {
    setSelected(null);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible); // Toggle form visibility
  };

  const openModal = (tournament) => {
    setSelectedTournament(tournament); // Set the tournament for modal
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedTournament(null); // Clear the selected tournament
  };

  if (loading) return <p>Loading for {state}...</p>;
  if (error) return <p>Error :(</p>;

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    if (isNaN(date)) {
      console.error('Invalid date');
      return 'Invalid date';
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month}-${day}`;
  };

  return (
    <div>
      <div className="title">
        Physical Tournaments
      </div>
      <div className="state-selector">
        <label htmlFor="state">Select State: </label>
        <select id="state" value={state} onChange={handleStateChange}>
          {states.map((state) => (
            <option key={state.value} value={state.value}>
              {state.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex">
        <div
          className={`geo-item selected ${selected === 'Local' ? 'selected' : ''}`}
          onMouseEnter={() => handleMouseEnter('Local')}
          onMouseLeave={handleMouseLeave}
        >
          Local
        </div>
        <div
          className={`geo-item ${selected === 'National' ? 'selected' : ''}`}
          onMouseEnter={() => handleMouseEnter('National')}
          onMouseLeave={handleMouseLeave}
        >
          National
        </div>
      </div>
      <div className="tournament-list">
        {data && data.getPhysicalTournaments && data.getPhysicalTournaments.map((tournament, index) => (
            <div 
              key={index} 
              className="tournament-item"
              onClick={() => openModal(tournament)} // Open modal on click
            >
              <p className="item-title">{tournament.title}</p>
              <p className="item-city">{tournament.city}</p>
              <p className="item-date">{formatDate(tournament.date)}</p>
            </div>
          ))}
      </div>
      <div className="w-100 flex fd-c">
        {isFormVisible && <PostPhysicalTournamentForm />} {/* Conditionally render the form */}
        <button
            className="post-physical-tournament"
            onClick={toggleFormVisibility}
            style={{ display: isFormVisible ? 'none' : 'block' }} // Conditionally set display to none
        >
            Post
        </button>
        </div>
        {isModalOpen && selectedTournament && (
        <PhysicalTournamentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedTournament.title}
          street={selectedTournament.street}
          city={selectedTournament.city}
          state={state}
          date={formatDate(selectedTournament.date)}
          hour={selectedTournament.hour}
          minute={selectedTournament.minute}
          bio={selectedTournament.bio}
          type={selectedTournament.type}
        />
      )}
    </div>
  );
};

export default PhysicalTourneys;