import React, { useState } from 'react';
import DebitCardWithdrawalPage from './DebitCardWithdrawal';
import SavePaymentMethods from './SavedPaymentMethods';
import WithdrawalOptions from './WithdrawalOptions';

const WalletWithdrawalPage = () => {
  const [selectedPayment, setSelectedPayment] = useState(null);

  return (
    <div className="withdrawal-page">
      {/* Header section */}
      <div className="flex header">
        <button type="button" tabIndex="0" className="previous">
          <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
            <path d="M36.998 53.995 16 32.998 36.998 12l6.306 6.306L28.61 33l14.694 14.694L36.998 54v-.005Z"></path>
          </svg>
          <span>Wallet</span>
          <span>/</span>
          <span className="white">Withdraw</span>
        </button>
      </div>
      
      <div className="content scroll">
      {selectedPayment === 'DEBIT_CARD' ? (
        <DebitCardWithdrawalPage />
      ) : (
        <WithdrawalOptions setSelectedPayment={setSelectedPayment}/>
      )}
      </div>
    </div>
  );
};

export default WalletWithdrawalPage;