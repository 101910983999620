import React from 'react';

const DateOfBirthSelect = ({ dobMonth, dobDay, dobYear, setDobMonth, setDobDay, setDobYear, error }) => {
  const handleMonthChange = (event) => {
    setDobMonth(event.target.value);
  };

  const handleDayChange = (event) => {
    setDobDay(event.target.value);
  };

  const handleYearChange = (event) => {
    setDobYear(event.target.value);
  };

  return (
    <label className="stacked samri-1ww0eyq">
      <div className="oneid-birthdate-strength-container"></div>
      <div className="input-content samri-zll7a8">
        <label className="samri-1ww0eyq">
          <div className="select-wrap samri-uvzug0">
            <div className="select-content samri-uvzug0">
              <select
                id="registration-dobMonth-input"
                className="select spacing-expanded placeholder-select samri-uvzug0"
                data-test="register-dob"
                name="dobmonth"
                value={dobMonth}
                onChange={handleMonthChange}
              >
                <option className="select-placeholder" value="" disabled>Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <div className="dropdown-icon-wrap samri-uvzug0">
                <svg fill="currentColor" viewBox="0 0 64 64" className="svg-icon">
                  <path d="M32.271 49.763 9.201 26.692l6.928-6.93 16.145 16.145 16.144-16.144 6.93 6.929-23.072 23.07h-.005Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </label>
        <label className="samri-1ww0eyq">
          <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
              <input
                id="registration-dobDay-input"
                className="input spacing-expanded samri-1pryo3e"
                type="number"
                name="dobday"
                max="31"
                data-test="register-dob"
                placeholder="Day"
                value={dobDay}
                onChange={handleDayChange}
              />
            </div>
          </div>
        </label>
        <label className="samri-1ww0eyq">
          <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
              <input
                id="registration-dobYear-input"
                className="input spacing-expanded samri-1pryo3e"
                type="number"
                name="dobyear"
                max="9999"
                data-test="register-dob"
                placeholder="Year"
                value={dobYear}
                onChange={handleYearChange}
              />
            </div>
          </div>
        </label>
      </div>
      <span className="label-content samri-5ecfln">
        <span>Date of Birth</span>
        <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
      </span>
      {error && <div className="error" style={{ fontSize: '12px' }}>{error}</div>}
    </label>
  );
};

export default DateOfBirthSelect;