import React from 'react';

const PaymentOptions = ({ setSelectedPayment }) => {
  return (
    <div className="margin">
      {/* Quick Deposit Section */}
      <div className="ab">
        <div className="order">
          <div className="line"></div>
          <div className="line-title">Quick Deposit</div>
          <div className="line"></div>
        </div>
      </div>

      <div className="payment-selection">
        {/* Debit Card Option */}
        <section className="payment-item">
          <div
            className="padding"
            data-test-value="NEW_DEBIT_CARD"
            onClick={() => setSelectedPayment('DEBIT_CARD')}
            style={{ cursor: 'pointer' }}
          >
            <div className="issuing-img">
              <div className="img-spc">
                <span>
                  <img
                    data-test-id="visaLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/visa.svg"
                    alt="Visa logo"
                  />
                </span>
                <span>
                  <img
                    data-test-id="mastercardLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/mastercard.svg"
                    alt="MasterCard logo"
                  />
                </span>
                <span>
                  <img
                    data-test-id="amexLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/amex.svg"
                    alt="Amex logo"
                  />
                </span>
              </div>
            </div>
            <div className="payment-style">
              <span>Debit Card</span>
            </div>
          </div>
        </section>

        {/* Paypal Option */}
        <section className="payment-item">
          <div
            className="padding"
            data-test-value="PAYPAL"
            onClick={() => setSelectedPayment('PAYPAL')}
            style={{ cursor: 'pointer' }}
          >
            <div className="issuing-img">
              <img
                className="image"
                src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/paypal.svg"
                alt="Paypal logo"
              />
            </div>
            <div className="payment-style">
              <span>Paypal</span>
            </div>
          </div>
        </section>

        {/* Credit Card Option */}
        <section className="payment-item">
          <div
            className="padding"
            data-test-value="NEW_CREDIT_CARD"
            onClick={() => setSelectedPayment('CREDIT_CARD')}
            style={{ cursor: 'pointer' }}
          >
            <div className="issuing-img">
              <div className="img-spc">
                <span>
                  <img
                    data-test-id="visaLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/visa.svg"
                    alt="Visa logo"
                  />
                </span>
                <span>
                  <img
                    data-test-id="mastercardLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/mastercard.svg"
                    alt="MasterCard logo"
                  />
                </span>
                <span>
                  <img
                    data-test-id="amexLogo"
                    className="image"
                    src="https://aw-web-assets.cpe.fanduel.com/fd-iwa-assets/graphics/card-payment-logos/amex.svg"
                    alt="Amex logo"
                  />
                </span>
              </div>
            </div>
            <div className="payment-style">
              <span>Credit Card</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PaymentOptions;