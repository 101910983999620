import React from "react";
import '../css/notificationMessage.css';

const NotificationMessage = ({ title, message }) => {
    return (
        <div className="notification-msg-placement">
            <div className="notification-title">{title}</div>
            <div className="notification-msg">{message}</div>
            <div className="timebar"></div> {/* Timebar div */}
        </div>
    );
};

export default NotificationMessage;