import React, { useState, useEffect } from 'react';

const WeeklyEloBox = () => {
  const [eloData, setEloData] = useState({
    classicalElo: null,
    lastWeekClassicalElo: 1200,
    bulletElo: null,
    lastWeekBulletElo: 1200,
    blitzElo: null,
    lastWeekBlitzElo: 1200,
    kingOfTheHillElo: null,
    lastWeekKingOfTheHillElo: null,
  });

  useEffect(() => {
    const fetchEloData = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);

        if (!token) {
          console.error('Authentication token not found');
          return;
        }
    
        // GraphQL query
        const query = `
          query {
            getWeeklyElos {
              classicalElo
              lastWeekClassicalElo
              bulletElo
              lastWeekBulletElo
              blitzElo
              lastWeekBlitzElo
              kingOfTheHillElo
              lastWeekKingOfTheHillElo
            }
          }
        `;
    
        const response = await fetch('https://grandhustler.com/graphql', {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ query }),
        });
    
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
    
        const data = await response.json();
       
        setEloData({
          classicalElo: data.data.getWeeklyElos.classicalElo,
          lastWeekClassicalElo: data.data.getWeeklyElos.lastWeekClassicalElo,
          bulletElo: data.data.getWeeklyElos.bulletElo,
          lastWeekBulletElo: data.data.getWeeklyElos.lastWeekBulletElo,
          blitzElo: data.data.getWeeklyElos.blitzElo,
          lastWeekBlitzElo: data.data.getWeeklyElos.lastWeekBlitzElo,
          kingOfTheHillElo: data.data.getWeeklyElos.kingOfTheHillElo,
          lastWeekKingOfTheHillElo: data.data.getWeeklyElos.lastWeekKingOfTheHillElo,
        });
      } catch (error) {
        console.error('Error fetching Elo data:', error);
      }
    };    
    

    fetchEloData();
  }, []);

  const calculateChange = (currentElo, lastWeekElo) => {
    if (currentElo !== null && lastWeekElo !== null) {
      return currentElo - lastWeekElo;
    } else {
      return currentElo - 1200;
    }
    return 0;
  };

  const getArrowIcon = (change) => {
    if (change > 0) {
      return (
        <svg
          viewBox="0 0 16 16"
          version="1.1"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
          className="arrow-icon"
        >
          <path fill="#ACFFC3" d="M16 2h-4l1.29 1.29-4.29 4.3-3-3-6 6v2.82l6-6 3 3 5.71-5.7 1.28 1.29 0.010-4z" />
        </svg>
      );
    } else if (change < 0) {
      return (
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow-icon error"
        >
          <path d="M1.85123 8.10628L0.437012 9.52049L7.50808 16.5916L13.872 10.2276L18.1147 14.4702L16.3723 16.2126L23.0644 18.0058L21.2713 11.3136L19.5289 13.056L13.872 7.39917L7.50808 13.7631L1.85123 8.10628Z" fill="#FF6F61" />
        </svg>
      );
    } else {
      return (
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow-icon"
          width={20}
        >
          <path d="M0,0H24V24H0Z" fill="none" />
          <path d="M22,12,18,8v3H3v2H18v3Z" fill="rgb(167, 167, 167)" />
        </svg>
      );
    }
  };

  const renderEloGroup = (title, currentElo, lastWeekElo) => {
    const change = calculateChange(currentElo, lastWeekElo);
    return (
      <div className="elo-group">
        <div className="fit-w">{title}</div>
        <div className="fit-w">
          (
          <span className="elo-value">
            {lastWeekElo !== null ? lastWeekElo : '1200'}
          </span>
          )
        </div>
        {getArrowIcon(change)}
        <div className="fit-w">
          (
          <span className="elo-value">
            {currentElo !== null ? currentElo : ' '}
          </span>
          )
        </div>
      </div>
    );
  };

  return (
    <div className="weekly-elo-box">
      <div className="weekly-elo">
        {renderEloGroup('Classical', eloData.classicalElo, eloData.lastWeekClassicalElo)}
        {renderEloGroup('Bullet', eloData.bulletElo, eloData.lastWeekBulletElo)}
        {renderEloGroup('Blitz', eloData.blitzElo, eloData.lastWeekBlitzElo)}
      </div>
      <div className="sidenote-message">* As compared to last week.</div>
    </div>
  );
};

export default WeeklyEloBox;
