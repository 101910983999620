import React, { useState } from 'react';
import WalletDepositPage from './WalletDepositPage'; // Import the deposit page
import WalletWithdrawalPage from './WalletWithdrawalPage';
import '../css/wallet.css';

const Wallet = ({ doubloons, closeModal }) => {
  const [showDepositPage, setShowDepositPage] = useState(false); // State to toggle deposit page
  const [showWithdrawalPage, setShowWithdrawalPage] = useState(false); // State to toggle withdrawal page

  const handleDepositClick = () => {
    setShowDepositPage(true); // Show deposit page when deposit button is clicked
    setShowWithdrawalPage(false); // Hide withdrawal page
  };

  const handleWithdrawClick = () => {
    setShowWithdrawalPage(true); // Show withdrawal page when withdraw button is clicked
    setShowDepositPage(false); // Hide deposit page
  };

  const goBack = () => {
    setShowDepositPage(false); // Go back to the wallet view from deposit page
    setShowWithdrawalPage(false); // Go back to the wallet view from withdrawal page
  };

  return (
    <div className="modal samri-siyed">
      <div className="overlay" onClick={closeModal}></div>
      <div className="card samri-siyed">
        {showDepositPage ? (
          <WalletDepositPage goBack={goBack} />
        ) : showWithdrawalPage ? (
          <WalletWithdrawalPage goBack={goBack} /> 
        ) : (
          <>
            <div className="flex header">
              <div>
                <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.71 13.349v.024c0 4.316-2.076 8.146-5.32 10.57H24.61l-.034-.024a13.19 13.19 0 0 1-5.286-10.57c0-7.296 5.914-13.21 13.21-13.21 7.296 0 13.21 5.914 13.21 13.21Z"
                  ></path>
                </svg>
                Wallet
              </div>
              <button type="button" className="x-button" onClick={closeModal}>
                <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                  <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path>
                </svg>
              </button>
            </div>
            <div className="content scroll">
              <div className="modal-content">
                <div className="content-padding">
                  <div className="container">
                    <div className="flex modal-space">
                      <div className="flex gap column">
                        <div className="currency">
                          <div className="currency-header">
                            <span>Your currency</span>
                            <span>Value</span>
                          </div>
                          <div className="doubloon">
                            <div className="doubloon-container">
                              <div className="doubloon-details">
                                <img
                                  src="/ghsite/public/images/doubloon.png"
                                  alt="doubloon"
                                  style={{ width: '45px', height: '35px', marginTop: '1px' }}
                                />
                                <div className="currency-container">
                                  <span className="marker">DB</span>
                                  <span className="marker-subscript">Doubloon Coins</span>
                                </div>
                              </div>
                              <div className="value-container">
                                <span>${doubloons}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sticky">
                        <div className="flow-currency-btns">
                          <button
                            type="button"
                            className="currency-buttons deposit"
                            onClick={handleDepositClick} // Handle deposit click
                          >
                            Deposit Doubloons
                          </button>
                          <button
                            type="button"
                            className="currency-buttons withdraw"
                            onClick={handleWithdrawClick} // Handle withdraw click
                          >
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Wallet;