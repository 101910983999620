import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Header from './Header';
import Sidebar from './SideBar';
import LobbyClock from './LobbyCountdown';
import ReadOnlyChessboard from './ReadOnlyChessboard'; // Updated import
import Leaderboard from './Leaderboard';
import NotificationMessage from './NotificationMessage';
import '../css/lobby.css';
import { useWebSocket } from '../contexts/WebSocketContext';

const GET_ME = gql`
  query GetMe {
    me {
      id
      username
      profilePic
      email
    }
  }
`;

const LobbyComponent = () => {
  const { socket } = useWebSocket();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [maxParticipants, setMaxParticipants] = useState(0);
  const [time, setTime] = useState('');
  const [notification, setNotification] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const { data, loading, error } = useQuery(GET_ME, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  });

  useEffect(() => {
    if (socket && roomId) {
      if (data && data.me) {
        const { id, username } = data.me;
        socket.emit('join_lobby', { roomId, userId: id, username });

        socket.on('user_joined_lobby', ({ username }) => {
          setUsers((prevUsers) => [...prevUsers, username]);
        });

        socket.on('current_users', ({ users, maxParticipants, time }) => {
          setUsers(users);
          setMaxParticipants(maxParticipants);
          setTime(time);
        });

        socket.on('bye-notification', (notificationData) => {
          console.log('you received a bye');
          setNotification(notificationData);

          setTimeout(() => {
            setNotification(null);
          }, 10000);
        });

        return () => {
          socket.off('user_joined_lobby');
          socket.off('current_users');
          socket.off('bye-notification');
        };
      }
    }
  }, [socket, roomId, data]);

  const handleTimeUp = () => {
    if (socket && roomId) {
      console.log('start the tournament!');
      socket.emit('start_tournament', { roomId });
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading user information</div>;

  // Example board position, replace with actual position if needed
  const boardPosition = 'start'; // You can use 'start' or any FEN string

  return (
    <div>
      <Header onToggleSidebar={toggleSidebar} />
      <div className='flex-fix'>
        <Sidebar isCollapsed={isSidebarCollapsed} /> 
        <div className={`flex board-standing-container ${isSidebarCollapsed ? "expanded" : ""}`}>
          <LobbyClock joined={users.length} capacity={maxParticipants} time={time} onTimeUp={handleTimeUp} />
          <div id="board-layout-main" className="board-layout-main d-flex mt-3" style={{ height: 'fit-content' }}>
            <ReadOnlyChessboard position={boardPosition} />
            <Leaderboard users={users} roomId={roomId} />
          </div>
        </div>
      </div>
      {notification && (
        <NotificationMessage title={notification.title} message={notification.message} />
      )}
    </div>
  );
};

export default LobbyComponent;