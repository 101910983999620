import React from 'react';

const SmallerLogo = () => {
  return (
    <div className="mgt-2 mgb-1">
      <div>
        <div className="smallerlogo"></div>
      </div>
    </div>
  );
};

export default SmallerLogo;