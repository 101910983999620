import React from 'react';
import '../css/ingame.css'; // Ensure this CSS file includes the styles used in the previous component


const LeaveConfirmation = ({ onConfirm, onCancel }) => {
  return (
    <div className="modal-backdrop">
      <div className="backdrop-padding">
        <div className="container-center">
          <div className="unfollow-question-div">
            <div className="inner-padding">
              <div className="question">Are you sure you want to leave?</div>
              <div className="sidenote" style={{ marginBottom: '8px' }}>
                You will be reimbursed your initial wager.
              </div>

              <div className="action-buttons">
                <button className="no" onClick={onCancel}>No, cancel</button>
                <div className="ml">
                  <button className="yes" onClick={onConfirm}>
                    <div>Yes, Leave</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="x-button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveConfirmation;