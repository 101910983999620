import React from 'react';

const InsufficientFundsModal = ({ tourneyName, closeModal, onOpenWallet }) => {
    return (
        <div className="modal-backdrop">
            <div className="backdrop-padding">
                <div className="container-center">
                    <div className="unfollow-question-div">
                        <div className="inner-padding">
                            <div className="question">Deposit Money to Create Swiss Tournament, {tourneyName}?</div>
                            <div className="sidenote error">Insufficient Funds</div>
                            <div className="action-buttons">
                                <button className="no" onClick={closeModal}>No, cancel</button>
                                <div className="ml">
                                    <button className="yes wallet" onClick={onOpenWallet}>Wallet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsufficientFundsModal;