import React from 'react';

const GHChessInfo = () => {
  return (
    <div className="ghchess-info">
        <div className="info-row">
            <section className="chess-rewards">
                <h2 className='chess-rewards-title'>What Type of Chess Matches and Rewards are Available on GHChess?</h2>
                <ul>
                <li><strong>Skill-Based Tournaments:</strong> Compete against players of similar skill levels in a fair and exciting environment. Winners of each round advance, and the top players earn cash prizes.</li>
                <li><strong>Free Play Events:</strong> Join free-entry tournaments and challenge your chess abilities. No entry fee required—just bring your best strategies to win.</li>
                <li><strong>Blitz Challenges:</strong> Engage in fast-paced Blitz tournaments with shorter time controls, perfect for players who thrive under pressure.</li>
                <li><strong>Daily Quads:</strong> Join daily quad tournaments where players are grouped by their GHChess ratings into groups of four. Winners in each group earn cash rewards.</li>
                <li><strong>Practice Matches:</strong> Hone your skills against players of your choice or participate in practice games to improve your strategies and tactics.</li>
                </ul>
            </section>

            <section className="chess-tournaments-online">
                <h2 className='chess-rewards-title'>Can I Compete in Chess Tournaments Online?</h2>
                <p>Yes! GHChess is one of the top platforms for online chess tournaments, offering players a secure and user-friendly way to enjoy competitive chess. Whether you're at home or on the go, you can join tournaments and win real cash prizes. To participate, simply create an account, sign up for a tournament, and start playing.</p>
                <p>GHChess supports play on desktop devices. Our platform is designed to provide a seamless experience. Make sure to log in and verify your account to join cash prize events.</p>
            </section>
            <section className="eligible-countries-states">
                <h2 className='chess-rewards-title'>Countries and States Eligible for GHChess Tournaments</h2>
                <p>GHChess tournaments are available to players in the United States of America and in states where skill-based competitions are legal. While GHChess offers tournaments globally, some restrictions may apply based on local laws and regulations.</p>
            </section>

            <section className="withdraw-winnings">
                <h2 className='chess-rewards-title'>How Do I Withdraw My Tournament Winnings?</h2>
                <p>GHChess makes it easy to withdraw your winnings securely. After winning a tournament, your cash prize will be added to your GHChess wallet. You can withdraw funds using any of the following methods:</p>
                <ul>
                <li><strong>PayPal:</strong> Fast and secure withdrawals directly to your PayPal account. Processing time: up to 48 hours.</li>
                <li><strong>Direct Bank Transfer:</strong> Transfer winnings to your bank account. Processing time: 3-5 business days.</li>
                <li><strong>Check:</strong> Receive a mailed check for your winnings. Processing time: up to 14 business days.</li>
                <li><strong>Digital Wallets:</strong> Use popular digital wallets like Venmo or Zelle for quick and convenient transfers.</li>
                </ul>
                <p>Withdrawal options and processing times may vary based on your country or state of residence.</p>
            </section>
            </div>
    </div>
  );
};

export default GHChessInfo;
