import React, { useEffect, useState } from 'react';

const LobbyClock = ({ joined, capacity, time, onTimeUp }) => {
  const [countdown, setCountdown] = useState('');
  const [hasStarted, setHasStarted] = useState(false); // New state to track if tournament has started

  useEffect(() => {
    if (!time) return;

    const calculateTimeLeft = () => {
      const [hours, minutes] = time.split(':').map(Number);
      const targetTime = new Date();
      targetTime.setHours(hours, minutes, 0, 0);
      const now = new Date();
      const difference = targetTime - now;

      if (difference > 0) {
        const minutesLeft = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsLeft = Math.floor((difference % (1000 * 60)) / 1000);
        setCountdown(`${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`);
      } else if (!hasStarted) { // Check if the tournament has already started
        setCountdown('00:00');
        onTimeUp(); // Trigger the tournament start when the countdown reaches zero
        setHasStarted(true); // Set the flag to true
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [time, onTimeUp, hasStarted]);

  return (
    <div className="lobby-clock">
      <div className="volume">
        <div className="num-joined">{joined}</div>
        <div className='cap'>/</div>
        <div className="cap num">{capacity}</div>
      </div>
      <div className="lobby-countdown">{countdown}</div>
      <div></div>
    </div>
  );
};

export default LobbyClock;
