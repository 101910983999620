import { useQuery, gql } from '@apollo/client';

const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      email
      username
      profilePic
      balance
    }
  }
`;

export function useUsers() {
  const { data, loading, error } = useQuery(GET_USERS);

  if (loading) return { loading: true, users: [] };
  if (error) {
    console.error('Error fetching data:', error);
    return { loading: false, users: [] };
  }
  return { loading: false, users: data.getUsers };
}