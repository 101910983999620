import React from 'react';

const PhysicalTournamentModal = ({ isOpen, onClose, title, street, city, date, state, hour, minute, bio, type }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="pt-modal-container">
        <div className="modal-header" style={{ padding: '1rem' }}>
          <img
            src="https://www.chess.com/bundles/web/images/user-image.007dad08.svg"
            alt="User"
            className="modal-header-img"
          />
          <h2 className='pt-header'>{title}</h2>
        </div>
        <div className="modal-body" style={{ padding: '1rem' }}>
          <p className='category-subtitle'><strong className='category-header'>City:</strong> {city}</p>
          <p className='category-subtitle'><strong className='category-header'>Street:</strong> {street}</p>
          <p className='category-subtitle'><strong className='category-header'>Date:</strong> {date}</p>
          <p className='category-subtitle'><strong className='category-header'>State:</strong> {state}</p>
          <p className='category-subtitle'><strong className='category-header'>Type:</strong> {type}</p>
          <p className='category-subtitle'><strong className='category-header'>Bio:</strong> {bio}</p>
          <p className='category-subtitle'><strong className='category-header'>Time:</strong> {hour}:{minute} (*military time*)</p>
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="pt-modal-close-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default PhysicalTournamentModal;