import React, { useEffect, useState } from "react";
import { useWebSocket } from "../contexts/WebSocketContext";

const OnlineFriend = () => {
  const [onlineFriends, setOnlineFriends] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const { socket } = useWebSocket();

  const fetchMutualFriends = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authorization token not found");

      const query = `
        query {
          getMutualFriends {
            id
            username
            profilePic
          }
        }
      `;

      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.errors?.[0]?.message || "Failed to fetch mutual friends");
      }

      setOnlineFriends(data.data.getMutualFriends);
    } catch (error) {
      console.error("Error fetching mutual friends:", error.message);
    }
  };

  useEffect(() => {
    fetchMutualFriends();
  }, []);

  const handleNewInvitation = (invitation) => {
    setInvitations((prevInvitations) => {
      const existingInvitation = prevInvitations.find(
        (inv) => inv.invitationId === invitation.invitationId
      );

      if (existingInvitation) {
        return prevInvitations.map((inv) =>
          inv.invitationId === invitation.invitationId ? invitation : inv
        );
      }
      return [...prevInvitations, invitation];
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on("send_invitation", handleNewInvitation);
      socket.on("new_invitation", handleNewInvitation);
    }

    return () => {
      if (socket) {
        socket.off("send_invitation", handleNewInvitation);
        socket.off("new_invitation", handleNewInvitation);
      }
    };
  }, [socket]);

  const handleDeleteInvitation = async (invitationId) => {
    try {
      setInvitations((prevList) =>
        prevList.filter((invitation) => invitation.invitationId !== invitationId)
      );
      console.log('im trying to delete');

      const token = localStorage.getItem("token");
      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            mutation RejectInvitation($invitationId: ID!) {
              rejectInvitation(invitationId: $invitationId) {
                success
                message
              }
            }
          `,
          variables: { invitationId },
        }),
      });

      const result = await response.json();

      if (!result.data.rejectInvitation.success) {
        console.error("Error rejecting invitation:", result.data.rejectInvitation.message);
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
    }
  };

  const handleAcceptInvitation = async (invitationId, wager) => {
    try {
      const wagerFloat = parseFloat(wager);
      const hasSufficientFunds = await checkFunds(wagerFloat);

      if (hasSufficientFunds) {
        await putMoneyInPot(wagerFloat);

        const token = localStorage.getItem("token");
        const response = await fetch("https://grandhustler.com/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `
              mutation AcceptInvitation($invitationId: ID!) {
                acceptInvitation(invitationId: $invitationId) {
                  success
                  message
                  roomId
                  color
                }
              }
            `,
            variables: { invitationId },
          }),
        });

        const result = await response.json();
        if (!result.data.acceptInvitation.success) {
          console.error("Error accepting invitation:", result.data.acceptInvitation.message);
        }
      } else {
        console.log("Insufficient funds");
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };

  const checkFunds = async (cleanedWager) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager },
        }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error("Error checking funds:", error);
      return false;
    }
  };

  const putMoneyInPot = async (cleanedWager) => {
    try {
      const token = localStorage.getItem("token");
      const freeCashPrize = false;

      if (!token) {
        throw new Error("No token found in localStorage");
      }

      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
              putMoneyInPot(wager: $wager, join: $join)
            }
          `,
          variables: {
            wager: cleanedWager,
            join: freeCashPrize,
          },
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
      }

      const data = await response.json();
      if (data.errors) {
        throw new Error(`GraphQL error: ${data.errors.map((err) => err.message).join(", ")}`);
      }

      return data;
    } catch (error) {
      console.error("Error putting money in pot:", error);
    }
  };

  return (
    <div className="online-friend">
      <div className="of-header">Friends</div>
      <div className="of-list">
        {onlineFriends.length > 0 ? (
          onlineFriends.map((friend) => (
            <div className="friend" key={friend.id}>
              <div className="friend-profile-pic">
                <img
                  src={
                    friend.profilePic ||
                    "https://www.chess.com/bundles/web/images/user-image.007dad08.svg"
                  }
                  height="32px"
                  alt={friend.username}
                  width="32px"
                  style={{ borderRadius: "8px", overflow: "hidden" }}
                />
              </div>
              <div className="info">
                <div className="username">{friend.username}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="center p2">You have no friends lol.</div>
        )}
      </div>

      <div className="of-header">Invitations</div>
      <div className="of-list">
        {invitations.length > 0 ? (
          invitations.map((invitation) => (
            <div className="invitation" key={invitation.invitationId}>
              <div className="info">
                <div className="username">{invitation.senderUsername}</div>
                <div className="wager">Wager: {invitation.wager}</div>
              </div>
              <div className="response-buttons">
                <button
                  className="reject"
                  onClick={() => handleDeleteInvitation(invitation.invitationId)}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                      fill="#0F1729"
                    ></path>
                  </svg>
                </button>
                <button
                  className="accept"
                  onClick={() => handleAcceptInvitation(invitation.invitationId, invitation.wager)}
                >
                  <div className="chessboard"></div>
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="center p2">No invitations yet.</div>
        )}
      </div>
    </div>
  );
};

export default OnlineFriend;
